.faqHeader {
    font-size: 27px;
    margin: 20px;
  }
  
  .panel-heading {
    position: relative;
    padding-right: 30px;
  }
  
  .FAQ-section {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #e9531d;
    justify-content: space-around;
    border-radius: 8px;
    padding: 10px;
    min-height: 70vh;
    padding: 10px;
    padding-bottom: 35px;
    background-color: #ffffff;
    padding-bottom: 50px;
    padding-top: 30px;
  }
  .panel-title a:hover {
    text-decoration: none !important;
  }
  .panel-title a {
    text-decoration: none !important;
    color: #4d4d60;
  }
  .panel-heading {
    background-color: #ffffff !important;
    min-height: 60px;
  }
  .panel-title {
    line-height: 38px !important;
  }
  .panel-default {
    margin-top: 10px !important;
  }
  .faqs-img-box {
    width: 50%;
    height: 720px;
  }
  .faqimg {
    width: 100%;
    height: 100%;
    margin-top: 30px;
  }
  .Faq-heading {
    font-size: 40px;
    color: #e9531d;
    font-weight: 600;
  }
  .comon-under-line {
    text-align: center;
    margin-bottom: 30px;
  }
  .orange-line {
    display: inline-block;
    width: 110px;
    height: 4px;
    border-radius: 6px;
    background-color: #e9531d;
  }
  .orange-line2 {
    display: inline-block;
    width: 140px;
    height: 4px;
    border-radius: 6px;
    background-color: #e9531d;
  }
  .Faq-head {
    font-size: 25px;
    font-weight: 700;
    color: #e9531d;
  }
  .Faq-head span {
    display: block;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 980px) {
    .faqs-img-box {
      display: none;
    }

  }
  @media screen and (min-width: 1000px) {
    .question-section {
      max-width: 650px;
    }
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(180deg) !important;
  }
  .accordion-flush .accordion-item .accordion-button {
    border: 1px solid rgb(182, 182, 182);
    border-radius: 5px;
    min-height: 60px;
    line-height: 25px;
  }
  .accordion-button:not(.collapsed) {
    color: #4d4d60;
    background-color: #ffffff;
  }
  .accordion-item {
    border: none;
  }
  /* /* .panel-heading [data-toggle="collapse"].collapsed:after { s */
  