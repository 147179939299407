.review-area {

    .slider-box{
        .more{
            color: lighten($primary-color, 10);
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @include max(992px) {
        padding: 3rem 0 7rem;
    }
    @include max(575px) {
        padding: 3rem 5px 7rem;
    }
    h2 {
        font-weight: 800;
        font-size: 42px;
        line-height: 44px;
        text-align: center;
        letter-spacing: -0.5px;
        text-transform: capitalize;
        color: #323232;
        margin: 0 0 40px;
        position: relative;
        @include max(992px) {
           font-size: 30px;
           line-height: 32px;
        }
        &:before {
            position: absolute;
            top: auto;
            right: 0;
            left: 0;
            bottom: -25px;
            width: 140px;
            height: 4px;
            background: #E9531D;
            border-radius: 2px;
            content: "";
            margin: 0 auto;
        }
    }
    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #404048;
        margin: 0 0 40px;
    }
    .slider-area {
        padding: 0;
        .slider-box {
            background: #FFFFFF url(../images/img23.webp) no-repeat right 21px top 18px;
            border: 1px solid #E0E0E0;
            border-radius: 10px;
            // padding: 60px 25px 0 25px;
            height: 250px;
            display: flex;
            flex-direction: column;
            align-items:center;
            gap:15px;
            justify-content: center;
            padding:1rem;
            h4 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
            }
            h3 {
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: #404040;
            }
            p {
                font-weight: 500;
                font-size: 17px;
                text-align: center;
                margin: 0;
                @include max(1367px) {
                    font-size: 14px;
                }



                &.h3{
                    color: #404040;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: center;
                    font-style: italic;
                }
                &.h4{
                    color: #000;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 29px;
                    text-align: center;
                    text-transform: capitalize;
                }

            }
        }
        .glide__bullets {
            bottom: -4rem;
            .glide__bullet {
                background: #e6e6e6;
                width: 15px;
                height: 15px;
                box-shadow: none;
                border: none;
                margin: 0 10px;
                position: relative;
            }
            .glide__bullet--active {
                background: #E9531D;
                &::before {
                    position: absolute;
                    top: -5px;
                    right: 0;
                    left: -5px;
                    bottom: 0;
                    content: "";
                    width: 25px;
                    height: 25px;
                    border: 1.5px solid #E9531D;
                    border-radius: 100%;
                }
            }
        }
    }

}