#footer {


    .con-info,
    ul{
        a{
            display: inline-block;
            padding: 4px 0;
            margin: 0;
            color: #d3d3d3;
        }
    }

    .con-info{
        a{
            padding-right: 15px;
            font-size: 1.2rem;
        }
        &.large a{

            margin-top: 15px;
            font-size: 1rem;;
            color:white;
        }
    }

    background: #404048;
    margin: 0;
    .footer-logo {
        a {
            display: block;
            img{
                width: auto;
                height:35px;
            }
        }
    }
    .con-info {
        margin: 0 0 40px;
        @include max(1367px) {
            margin: 0 0 20px;
        }
        @include max(992px) {
            margin: 0 0 30px;
            h2 {
                font-weight: 700;
                font-size: 25px;
                line-height: 27px;
                color: #FFFFFF;
                margin: 0 0 20px;
            }
        }
        @include max(575px) {
            h2 {
                font-size: 20px;
                line-height: 24px;
            }
        }
        h3 {
            span {
                margin: 0 15px 0 0;
                @include max(1367px) {
                    margin: 0 10px 0 0;
                }
                img {
                    height: 0.8em;
                    width: auto;
                }
            }
            a {
                font-weight: 600;
                font-size: 24px;
                line-height: 26px;
                color: #FFFFFF;
                @include max(1367px) {
                    font-size: 20px;
                    line-height: 22px;
                }
                @include max(1200px) {
                    font-size: 18px;
                    line-height: 20px;
                }
                @include max(992px) {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }
    }
    .s-link {
        // margin: 0 0 50px;
        @include max(1367px) {
            // margin: 0 0 25px;
        }
        @include max(992px) {
            padding: 30px 0;
            background: #404048;
            border-top: 1px solid #606060;
            ul {
                justify-content: center;
            }
        }
        ul {
            display: flex;
            li {
                margin: 0 20px 0 0;
                &:last-child {
                    margin: 0;
                }
                a {
                    display: inline-block;
                }
            }
        }
    }
    .card-block {
        @include max(992px) {
            text-align: center;
            margin: 0 0 30px;
            h3 {
                justify-content: center;
            }
        }
        &>img {
            margin: -11px 3px 0 2px;
        }
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #FFFFFF;
            opacity: 0.8;
            display: flex;
            align-items: center;
            margin: 0 0 10px;
            img {
                margin: 0 10px 0 0;
            }
        }
    }
    .page-bar {
        padding: 0.5rem 0 0 4rem;
        h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: #E9531D;
            margin: 0 0 20px;
        }
        ul {
            li {
                margin: 0 0 15px;
                a {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 18px;
                    color: #FFFFFF;
                    opacity: 0.8;
                }
            }
        }
    }
    .form-block {

        h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: #E9531D;
            margin: 0 0 20px;
        }
        form {
            input {
                background: rgba(255, 255, 255, 0.06);
                border: 1px solid #FFFFFF;
                border-radius: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #FFFFFF;
                opacity: 0.75;
                padding: 15px;
                &:focus {
                    box-shadow: none;
                }
                &::placeholder {
                    color: #fff;
                }
            }
            textarea {
                background: rgba(255, 255, 255, 0.06);
                border: 1px solid #FFFFFF;
                border-radius: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #FFFFFF;
                opacity: 0.75;
                padding: 15px;
                height: 160px;
                &:focus {
                    box-shadow: none;
                }
                &::placeholder {
                    color: #fff;
                }
                @include max(1367px) {
                    height: 120px;
                }
            }
            .sub-btn {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #FFFFFF;
                background: #E9531D;
                border-radius: 8px;
                width: 100%;
                padding: 12px 15px;
            }
        }
    }
    .copy-right {
        background: #2A2831;
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #FFFFFF;
            margin: 0;
            padding: .8rem 0;
        }
    }
}

.accordion-block {
    margin: 0 0 25px;
    @include max(575px) {
        padding: 0 10px;
    }
    h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        margin: 0 0 25px;
        @include max(575px) {
            margin: 0 0 15px;
        }
    }
    .accordion-item {
        background: none;
        border: none;
        .accordion-button {
            background: none;
            padding: 12px 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            color: #FFFFFF;
            &:focus {
                box-shadow: none;

            }
            &:after {
           //     background-image: url(../images/arrow-right.webp);
                background-repeat: no-repeat;
                background-size: 50%;
            }
            @include max(575px) {
                font-size: 18px;
                line-height: 19px;
                padding: 10px 0;
                font-weight: 500;
            }
        }
        .accordion-body {
            padding: 0;
            ul {
                li {
                    margin: 0 0 7px;
                    a {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}
.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
    transform: rotate(90deg) !important;
}