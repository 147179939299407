.location-section {
    .underline6 {
        display: inline-block;
        width: 140px;
        height: 4px;
        border-radius: 2px;
        background-color: #E9531D;
    }

    .location-text-1,
    .location-text-2,
    .location-text-3 {
        color: #404048;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 1px; /* is this correct/intended design? */
        text-align: left;
    }

    h2 {
        color: #E9531D;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
    }

    .location-text-4,
    .location-text-5 {
        color: #404048;
        line-height: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: justify;
        margin-left: 5px;
    }
}
@media screen and (max-width: 756px) {
    .location-section {
        padding-left: 30px;
        padding-right: 30px;

        img {
            margin-top: 20px;
        }
    }
}
