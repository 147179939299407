.thankyou_emerdency {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    .thankyou-heading {
      width: 100%;
      text-align: center;
  
      .thankyou-head {
        font-size: 45px;
        font-weight: 600;
        color: #e9531d;
      }
      .thankyou-line {
        display: inline-block;
        width: 190px;
        height: 3px;
        background-color: #e9531d;
      }
    }
    .inner-thanks {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      color: #000000;
      font-size: 30px;
      font-weight: 700;
      text-transform: capitalize;
      text-align: center;
    }
  }
  @media screen and (max-width:850px) {
    .thankyou_emerdency {
      width: 100%;
    }
    .thankyou_emerdency .inner-thanks {
      font-size: 20px;
    }
    .thankyou_emerdency .thankyou-heading .thankyou-head {
      font-size: 30px;
    }
    .thankyou-line {
      background-color: #dadada !important;
      width: 80% !important;
      height: 1px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width:484px) {
    .thankyou_emerdency .inner-thanks {
      font-size: 15px;
    }
    .thankyou_emerdency .thankyou-heading .thankyou-head {
      font-size: 25px;
    }
  }
  @media screen and (max-width:390px) {
    .thankyou_emerdency .inner-thanks {
      font-size: 12px !important;
    }
  }
  .inner-thanks{
    padding: 40px;
    position: relative;
    width: 100% !important;
    margin-top: 10px !important;

  }
  .right-teeth{
    position: absolute;
    bottom: 0px;
    right: 12%;
  }
  .lift-teeth{
    position: absolute;
    top: 0px;
    left:15%;
  }
  @media screen and (max-width:1280px) {
    .right-teeth{
    right: 8%;
  }
  .lift-teeth{
    position: absolute;
    top: 0px;
    left:10%;
  }

    
  }
  @media screen and (max-width:1099px) {
    .right-teeth{
    right: 5%;
  }
  .lift-teeth{
    position: absolute;
    top: 0px;
    left:7%;
  }

    
  }
  @media screen and (max-width:593px) {
    .right-teeth{
    right: 3%;
  }
  .lift-teeth{
    position: absolute;
    top: -10px;
    left:5%;
  }
    
  }
  @media screen and (max-width:860px) {
    .thankyou-line{
      display: none !important;
    }
    
  }
