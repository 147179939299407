.location-landing {
  .heading-underline {
    margin: 0 0 2rem;

    &::after {
      left: 0;
      width: 150px;
      bottom: -10px;
    }
  }

  h3 {
    color: $primary-color;
    margin-bottom: 0.8rem;
  }

  p {
    margin-bottom: 1.2rem;
  }

  img {
    margin: 0 auto;
    max-width: 75%;
    height: auto;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      &::before {
        color: #e8541c;
        content: "✓";
        display: table-cell;
        font-size: 30px;
        margin-right: 10px;
        width: 30px;
        height: 40px;
      }
    }
  }
}

.alt-list {
  display: flex;
  align-items: flex-start !important;
  &::before {
    color: #e8541c;
    content: "✓";
    display: table-cell;
    font-size: 30px;
    margin-right: 10px;
    width: 30px;
    height: 40px;
  }
}
