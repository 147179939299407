.blogarea {
    width: 90%;
    border-radius: 5px;
    border: 1px solid #e9531d;
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    p {
      color: #4d4d60;
      font-size: 16px;
      font-style: normal;
      line-height: 20px;
    }
  }
  .top-blog-head {
    text-align: center;
    p {
      font-size: 30px;
      font-weight: 700;
      color: #e9531d;
      margin-bottom: 30px;
      line-height: 35px;
    }
  }
  .blog-s-date {
    color: #4d4d60;
    font-size: 16px;
    font-style: normal;
    margin-bottom: 15px;
    line-height: 20px;
  }
  .cavity-question p {
    font-size: 28px;
    color: #e9531d;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 20px;
  }
  .cavity-ans {
    font-size: 16px;
    color: #4d4d60;
    margin-top: 10px;
    line-height: 20px;
  }
  .symtoms-question p {
    color: #e9531d;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .symtoms-ans {
    font-size: 16px;
    color: #4d4d60;
    font-style: normal;
    line-height: 25px;
  }
  .symtoms-ans p {
    font-size: 16px;
    color: #4d4d60;
    font-style: normal;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .symtoms-ans ul li {
    list-style: disc;
    margin-left: 25px;
  }
  .cause-question p {
    color: #e9531d;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .plaqu-info {
    color: #e9531d;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .acid-attack-head {
    color: #e9531d;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .pulp-head {
    color: #e9531d;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .risk-factor span {
    display: inline-block;
    color: #e9531d;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
    // margin-top: 20px;
    // margin-bottom: 10px;
  }
  .location-teeth {
    color: #e9531d;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .brushing {
    color: #e9531d;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .snacking {
    color: #e9531d;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .dry-mouth {
    color: #e9531d;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .floride {
    color: #e9531d;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .copli-head span {
    display: inline-block;
    color: #e9531d;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .small-orange-color {
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: #e9531d;
    border-radius: 50%;
    margin-right: 10px;
  }
  .serve-box {
    display: flex;
    font-size: 18px;
    align-items: center;
    color: #e9531d;
    font-weight: 600;
    margin-top: 15px;
  }
  .serve-box2 {
    display: flex;
    font-size: 18px;
    color: #e9531d;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .prevention p {
    color: #e9531d;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .simple-text {
    font-size: 16px;
    color: #4d4d60;
    margin-top: 10px;
    line-height: 20px;
  }
  .orange-line-down {
    display: inline-block;
    width: 120px;
    height: 5px;
    background-color: #e9531d;
    border-radius: 50px;
  }
  .orange-line-down2 {
    display: inline-block;
    width: 100px;
    height: 3px;
    background-color: #e9531d;
    border-radius: 50px;
  }
  .orange-line-down3 {
    display: inline-block;
    width: 140px;
    height: 3px;
    background-color: #e9531d;
    border-radius: 50px;
  }
  .plaqu-info {
    display: flex;
    align-items: center;
  }
  .blog-heading-top {
    margin-bottom: 40px;
  }
  .top-blog-head2 {
    text-align: center;
    p {
      font-size: 40px;
      font-weight: 800;
      color: #e9531d;
      font-style: normal;
    }
  }
  @media screen and (max-width: 900px) {
    .top-blog-head {
      text-align: start;
      line-height: 70px;
    }
    .top-blog-head p {
      line-height: 1;
    }
    .for-hide-line {
      display: none;
    }
  }
  @media screen and (max-width: 700px) {
    .blogarea p {
      font-size: 12px;
    }
    .symtoms-ans {
      font-size: 12px;
    }
    .simple-text {
      font-size: 12px;
    }
  
    .top-blog-head p {
      line-height: 1;
    }
    .cavity-question p {
      margin-bottom: 0;
      font-size: 15px;
    }
    .orange-line-down2 {
      width: 60px;
      height: 3px;
      margin-top: 7px;
    }
    .symtoms-question p {
      margin-bottom: 0;
      font-size: 15px;
    }
    .cause-question p {
      margin-bottom: 0;
      font-size: 15px;
    }
    .small-orange-color {
      width: 5px;
      height: 5px;
    }
    .plaqu-info {
      margin-bottom: 8px;
      font-size: 13px;
    }
    .top-blog-head p {
      font-size: 17px;
      line-height: 25px;
    }
    .risk-factor > p {
      margin-top: 0px;
    }
    .copli-head span {
      margin-bottom: 0;
      font-size: 15px;
    }
  
    .serve-box {
      font-size: 12px;
      span {
        margin-left: 10px;
      }
    }
    .serve-box2 {
      font-size: 12px;
    }
    .prevention p {
      margin-bottom: 0;
      font-size: 15px;
    }
  }
  