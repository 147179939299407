.views-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .view-heading {
    text-align: center;
    margin-bottom: 30px;
  }
  .view-head {
    font-size: 45px;
    color: #e9531d;
    font-weight: 600;
  }
  .view-head2 {
    font-size: 25px;
    color: #e9531d;
    font-weight: 600;
  }
  .view-line {
    display: inline-block;
    width: 270px;
    height: 3px;
    background-color: #e9531d;
    transform: translateY(-10px);
  }
  .inner-view {
    margin-top: 60px;
    width: 100%;
    .view-nav {
      width: 100%;
      border-bottom: 2px solid #dadada;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
  
      .appoint-box {
        flex-basis: 14%;
  
        span {
          svg {
            width: 20px;
          }
        }
      }
      .appoint-texts2 {
        flex-basis: 20% !important;
      }
      .appoint-texts {
        color: #676767;
        font-size: 15px;
        font-weight: 600;
        margin-left: 5px;
      }
    }
    .view-detail-card {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  
      .appoint-person-name {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        font-size: 23px;
        font-weight: 500;
        color: #ffffff;
        background-color: #e9531d;
        line-height: 50px;
      }
      .view-inner-detail-card {
        background-color: transparent;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
  
      .view-inner-detail-box {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
  
        padding-bottom: 50px;
  
        .patient-info-box {
          flex-basis: 25%;

          background-color: white;
          padding: 1rem;
          border-radius: 5px;
  
          .pateint-info-bold {
            color: #000000;
            font-size: 15px;
            font-weight: 800;
          }
          .pateint-info-light {
            color: #676767;
            font-size: 13px;
            margin-top: 4px;
          }
          .patient-detail-box {
            margin-bottom: 35px;
          }
        }
      }
    }
    .view-inner-detail-box-mbl {
      justify-content: unset !important;
    }
    .leave-review-btn {
      padding-bottom: 20px;
  
      p {
        width: 250px;
        height: 40px;
        background-color: #e9531d;
        box-shadow: 0px 0px 2px #b8b5b5;
        line-height: 40px;
        color: #ffffff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  
  .inner-view {
    margin-top: 60px;
    width: 100%;
    .view-nav {
      width: 100%;
      border-bottom: 2px solid #dadada;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
  
      .appoint-box {
        flex-basis: 14%;
  
        span {
          svg {
            width: 20px;
          }
        }
      }
      .appoint-texts2 {
        flex-basis: 20% !important;
      }
      .appoint-texts {
        color: #676767;
        font-size: 15px;
        font-weight: 600;
        margin-left: 5px;
        margin-top: 5px !important;
      }
    }
    .appoints-detail-card {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      border-radius: 15px;
      box-shadow: 0px 0px 10px #dadada;
      .appoint-person-name {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        font-size: 23px;
        font-weight: 500;
        color: #ffffff;
        background-color: #e9531d;
        line-height: 50px;
      }
      .appoint-inner-detail-card {
        background-color: #fff7f4;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
      .appoint-inner-detail-box-mbl {
        padding-top: 30px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
  
        padding-bottom: 50px;
  
        .patient-info-box {
          display: flex;
          flex-direction: row;
          padding-left: 30px;
  
          .pateint-info-bold {
            color: #000000;
            font-size: 15px;
            font-weight: 800;
          }
          .pateint-info-light {
            color: #676767;
            font-size: 13px;
            margin-top: 4px;
          }
          .patient-detail-box {
            flex-basis: 45%;
            margin-bottom: 15px;
          }
        }
      }
    }
    .leave-review-btn {
      padding-bottom: 20px;
  
      p {
        width: 250px;
        height: 40px;
        background-color: #e9531d;
        box-shadow: 0px 0px 2px #b8b5b5;
        line-height: 40px;
        color: #ffffff;
        margin-left: auto;
        margin-right: auto;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  
  @media screen and (max-width: 1120px) {
    .appoint-box {
      flex-basis: 20% !important;
  
      .appoint-texts {
        font-size: 11px !important;
      }
      span {
        svg {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
  }
  
  .Mobile-version-view {
    display: none;
  }
  @media screen and (max-width: 1000px) {
    .inner-view
      .appoints-detail-card
      .appoint-inner-detail-box
      .patient-info-box {
      flex-basis: 30%;
    }
    .inner-view .appoints-detail-card .appoint-inner-detail-box {
      padding-left: 20px;
    }
  }
  @media screen and (max-width:890px) {
    .inner-view
      .appoints-detail-card
      .appoint-inner-detail-box
      .patient-info-box
      .pateint-info-light {
      font-size: 11px;
    }
  }
  .add-kid {
    display: none;
  }
  .Mobie-version-view {
    display: none;
  }
  @media screen and (max-width: 794px) {
    .inner-view .view-detail-card .view-inner-detail-box .patient-info-box {
      flex-basis: 50%;
    }
    .inner-view .view-detail-card {
      width: 90% !important;
    }
    .inner-view .appoints-detail-card .appoint-person-name {
      font-size: 18px !important;
    }
    .Desktop-version-view {
      display: none !important;
    }
    .Mobie-version-view {
      display: block;
    }
  
    .appoint-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 15px;
    }
    .add-child {
      display: none !important;
    }
    .add-kid {
      display: block !important;
    }

    .view-head {
      font-size: 30px;
    }
    .view-line {
      width: 80%;
      background-color: #dadada;
      height: 1px;
      transform: translateX(0px);
    }
  }
  
  @media screen and (max-width: 431px) {
    .inner-view
      .view-detail-card
      .view-inner-detail-box
      .patient-info-box
      .pateint-info-light {
      font-size: 12px !important;
    }
  }
  
  @media screen and (max-width: 629px) {
    .views-section {
      width: 100%;
    }
  }
  @media screen and (max-width: 525px) {
    .inner-view .appoints-detail-card {
      width: 85%;
    }
    .inner-view
      .appoints-detail-card
      .appoint-inner-detail-box-mbl
      .patient-info-box
      .pateint-info-light {
      font-size: 11px;
    }
  }
  @media screen and (max-width: 370px) {
    .inner-view .appoints-detail-card {
      width: 95%;
    }
    .inner-view
      .appoints-detail-card
      .appoint-inner-detail-box-mbl
      .patient-info-box
      .pateint-info-light {
      font-size: 10px;
    }
  }
  