

.video-block{
  h2{
    color: #323232;
    font-size: 2.5rem;
    font-weight: 800;
    letter-spacing: -.5px;
    line-height: 50px;
    margin: 0 0 35px;
    position: relative;
    text-align: left;
    text-transform: capitalize;

    &::before {
      background: #e9531d;
      border-radius: 2px;
      bottom: -15px;
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      right: auto;
      top: auto;
      width: 100px;
    }
  }
  p{
    margin-bottom: 1rem;
  }
}


.mo-box2 {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px 25px;
  height: 210px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  @include max(1200px) {
    height: 180px;
    padding: 15px;
  }
  @include max(992px) {
    height: 160px;
    margin: 0 0 20px;
  }
  &:hover {
    background: #e9531d;
    p {
      color: #fff;
    }
    h3 {
      color: #fff;
    }
  }
  h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    margin: 0 0 20px;
    @include max(1200px) {
      margin: 0 0 15px;
    }
    @include max(992px) {
      margin: 0 0 10px;
    }
    @include max(575px) {
      font-size: 16px;
      line-height: 18px;
    }
    @include max(370px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #404048;
    margin: 0;
    text-align: left;
    @include max(992px) {
      line-height: 28px;
    }
    @include max(767px) {
      line-height: 22px;
    }
    @include max(575px) {
      line-height: 18px;
      font-size: 14px;
    }
    @include max(370px) {
      line-height: 16px;
      font-size: 13px;
    }
  }
}

.mo-block2 {
  margin-top: 120px;
  margin-bottom: 130px;
  padding: 15rem 0 15rem;
  background: url(../images/banner03.webp) no-repeat center top;
  background-size: 100%;
  position: relative;
  z-index: 99;
  @include max(1800px) {
    padding: 13rem 0 13rem;
  }
  @include max(1700px) {
    padding: 12rem 0 12rem;
  }
  @include max(1600px) {
    padding: 11rem 0 11rem;
  }
  @include max(1500px) {
    padding: 10rem 0 10rem;
  }
  @include max(1400px) {
    padding: 9rem 0 9rem;
  }
  @include max(1367px) {
    padding: 7rem 0 7rem;
  }
  @include max(1200px) {
    padding: 6rem 0 6rem;
  }
  @include max(1092px) {
    padding: 4rem 0;
    background: #fdeee8;
  }
  @include max(992px) {
    padding: 2rem 0;
    background: #fdeee8;
  }
  @include max(575px) {
    padding: 2rem 5px;
  }
  h2 {
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #000000;
    margin: 0 0 30px;
    text-align: center;
    @include max(992px) {
      font-size: 30px;
      line-height: 32px;
      margin: 0 0 20px;
    }
  }
  p {
    font-weight: 500;
    font-size: 17px;
    // line-height: 26px;
    color: #404048;
    font-weight: 500;
    // margin: 0 0 60px;
    text-align: center;
    @include max(1150px) {
      // margin: 0 0 20px;
    }
    @include max(992px) {
      font-size: 20px;
      line-height: 22px;
    }
    @include max(992px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.mo-box2 {
  margin-top: 45px;
  position:relative;
  width:100%;
  p {
    text-align: start;
    color: #404048;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }

  &::before{
    content: url(/assets/images/for_about.webp);
    position: absolute;
    top: -10px;
    right: -10px;
  }

}
.promise-heading {
  text-align: center;
  h2 {
    margin-top: -50px;
  }

  .promo-line {
    display: inline-block;
    background-color: #e9531d;
    width: 120px;
    height: 3px;
    border-radius: 5px;
    transform: translateY(-25px);
  }
}
@media screen and (max-width: 1381px) {
  .promise-heading h2 {
    margin-top: 0px;
  }
}
@media screen and (max-width: 991px) {
  .mo-box2 {
    p {
      text-align: start;
      color: #404048;
      font-size: 13px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}
@media screen and (max-width: 751px) {
  .mo-box2 {
    height: 180px;
    p {
      text-align: start;
      color: #404048;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}
@media screen and (max-width: 435px) {
  .mo-box2 {
    height: 190px;
    p {
      text-align: start;
      color: #404048;
      font-size: 13px;
      line-height: 19px;
      font-weight: 400;
    }
  }
}
@media screen and (max-width: 355px) {
  .mo-box2 {
  height: 210px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
    p {
      text-align: start;
      color: #404048;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}
.promo-text p {
  line-height: 25px;
}
.About-us-heading {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;

  p {
    font-size: 40px;
    font-weight: 800;
    color: #e9531d;
    font-style: normal;
  }
  .about-under-line {
    display: inline-block;
    width: 140px;
    height: 5px;
    background-color: #e9531d;
    border-radius: 50px;
  }
}
@media screen and (max-width: 990px) {
  .about-under-line {
    display: none !important;
  }
  .its-all-started-h {
    margin-top: 20px !important;
  }
  .video-block .vid-text p {
    margin: 0 0 10px;
    line-height: 22px;
    margin: 25px;
  }
  .ordering {
    order: 1;
  }
}

