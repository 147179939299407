// Responsive media query

@mixin min($size) {
	@media (min-width: $size) {
		@content;
	}
}

@mixin max($size) {
	@media (max-width: $size) {
		@content;
	}
}

@mixin min-max($min, $max) {
	@media (min-width: $min) and (max-width: $max) {
		@content;
	}
}
