.getin-touct {
    width: 100%;
    .inner-get-data {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  
    .get-in-heading {
      width: 100%;
    }
    .get-in-form {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      color: #01021d;
      ::placeholder {
        color: #01021d;
        font-size: 14px;
        font-weight: 600;
      }
      input,
      select {
        margin-bottom: 20px;
        height: 50px;
        color: #01021d;
        color: #01021d;
        font-size: 14px;
        font-weight: 600;
      }
      .Send-Form {
        height: 50px;
        width: 100%;
        background-color: #e9531d;
        border-radius: 7px;
        text-align: center;
        color: #ffffff;
        line-height: 50px;
      }
    }
  }
  .touch-sentance {
    margin-top: 20px;
    color: #4d4d60;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    text-align: center;
    margin-bottom: 20px;
  }
  .get-in-form2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: #01021d;
  
    input,
    select {
      margin-bottom: 10px;
      height: 50px;
      color: #01021d;
    }
  
    .reason-input {
      margin-top: -15px;
    }
    .Send-Form {
      height: 50px;
      width: 100%;
      background-color: #e9531d;
      border-radius: 7px;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
    }
    .custom-number-input {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      display: flex;
      border: 1px solid #dee2e6;
      align-items: flex-start;
      padding-left: 5px;
      input {
        height: 90%;
      }
      select {
        height: 90%;
        color: #6b7280;
      }
  
      input {
        border: none;
      }
      select {
        border: none;
      }
    }
  }
  
  .get-in-form label {
    margin-bottom: 1px;
    color: #404048;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  @media screen and (max-width: 944px) {
    .inner-get-data {
      width: 70% !important;
    }
  }
  #mobile-get {
    display: none;
  }
  @media screen and (max-width: 750px) {
    #desktop-get {
      display: none;
    }
    #mobile-get {
      display: block;
    }
  }
  