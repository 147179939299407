.treat-area {
  @include max(992px) {
    padding: 0px;
  }
  @include max(575px) {
    padding: 0px;
  }
  h2 {
    font-weight: 800;
    font-size: 42px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #323232;
    margin: 0 0 35px;
    position: relative;
    @include max(992px) {
      font-size: 35px;
      line-height: 37px;
      margin: 0 0 25px;
    }
    &:before {
      position: absolute;
      top: auto;
      right: 0;
      left: 0;
      bottom: -30px;
      width: 200px;
      height: 5px;
      background: #e9531d;
      border-radius: 2px;
      content: "";
      margin: 0 auto;
      @include max(992px) {
        width: 150px;
      }
    }
  }

  .long-bar {
    margin: 40px 0 0;
    background: #ffffff;
    border: 1px solid #e9531d;
    border-radius: 10px;
    ul {
      display: flex;
      .treatment-fees-tab {
        width: 100%;
      }
      li {
        width: 50%;
        &:first-child {
          .nav-link.active {
            &:before {
              left: 48px;
              right: auto;
              transform: rotate(180deg);
            }
          }
        }
        &:last-child {
          button,
          h3 {
            border-radius: 0 10px 0 0;
          }
        }
        button,
        h3 {
          width: 100%;
          padding: 15px 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fdeee8;
          font-weight: 600;
          font-size: 24px;
          line-height: 26px;
          color: #333333;
          position: relative;
          border-radius: 10px 0 0 0;
          z-index: 1;
          border-bottom: 1px solid #e9531d;
          @include max(1200px) {
            font-size: 20px;
            line-height: 22px;
          }
          @include max(992px) {
            padding: 20px 30px;
          }
          @include max(767px) {
            padding: 15px 15px;
            font-size: 16px;
            line-height: 20px;
          }
          @include max(575px) {
            font-size: 12px;
            padding: 15px 10px;
            span {
              font-size: 14px;
            }
          }
        }
        .nav-link.active-treatment {
          border-radius: 10px 10px 0 0;
        }

        .nav-link.active {
          border-radius: 10px 0 0 0;
          background: #e9531d;
          border: 1px solid #e9531d;
          color: #fff;
          &::before {
            position: absolute;
            top: auto;
            left: auto;
            right: 45px;
            bottom: -14px;
            content: "";
            background: url(../images/icon08.webp) no-repeat;
            width: 25px;
            height: 45px;
            background-size: 100%;
            z-index: -1;
            @include max(767px) {
              right: 29px;
              width: 15px;
              height: 30px;
            }
          }
        }
      }
    }
    .sm-two {
      padding: 3rem 0;
      @include max(992px) {
        padding: 2rem 1rem;
      }
      @include max(767px) {
        padding: 1rem 1.5rem 0;
      }
      @include max(575px) {
        padding: 1rem 1rem 0;
      }
      .block {
        display: flex;
        align-items: center;
        @include max(767px) {
          margin: 0 0 25px;
        }
        @include max(575px) {
          img {
            width: 25px;
          }
        }
        h3 {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #404048;
          padding: 0 0 0 1rem;
          @include max(1367px) {
            font-size: 17px;
            line-height: 21px;
          }
          @include max(992px) {
            padding: 0 0 0 1rem;
          }
          @include max(767px) {
            font-size: 14px;
            line-height: 16px;
            color: #000;
            font-weight: 600;
          }
          @include max(767px) {
            font-size: 12px;
            line-height: 14px;
            img {
              width: 25px;
            }
          }
        }
      }
    }
    .new {
      padding: 1.5rem 2rem 0;
      @include max(767px) {
        padding: 1rem 1.5rem 0;
      }
      @include max(575px) {
        padding: 1rem 1rem 0;
      }
      .block {
        margin: 0 0 50px;
        @include max(1200px) {
          margin: 0 0 30px;
        }
        h3 {
          padding: 0 0 0 1rem;
        }
      }
    }
  }
}

.after-left {
  &:after {
    left: 0;
    max-width: 200px;
  }
}
