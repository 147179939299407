.login-section {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .login-heading {
    text-align: center;
    margin-bottom: 30px;
  }
  .login-head {
    font-size: 45px;
    font-weight: 600;
  }
  .login-line {
    display: inline-block;
    width: 90px;
    height: 3px;
    background-color: #e9531d;
    transform: translateY(-10px);
  }

  .login-field-box1 {
    width: 100%;
    height: 55px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding: 15px;
    background-color: #ffffff;
    position: relative;
    box-shadow: 2px 2px 2px rgb(185, 184, 184);
  }
  .login-field-box1::before {
    position: absolute;
    content: "Email Or User ID";
    top: -10px;
    background-image: linear-gradient(#fffcf6, #ffffff);
    padding-left: 4px;
    padding-right: 4px;
    color: #e9531d;
    font-weight: 600;
    font-size: 13px;
  }
  .login-field-box1 input {
    color: #000000;
    font-weight: 700;
    width: 100%;
    background-color: transparent;
  }
  
  .login-field-box2 {
    width: 100%;
    height: 55px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding: 15px;
    background-color: #ffffff;
    position: relative;
    box-shadow: 2px 2px 2px rgb(185, 184, 184);
  }
  .login-field-box2::before {
    position: absolute;
    content: "Password";
    top: -10px;
    background-image: linear-gradient(#fffcf6, #ffffff);
    padding-left: 4px;
    padding-right: 4px;
    color: #e9531d;
    font-weight: 600;
    font-size: 13px;
  }
  .login-field-box2 input {
    color: #000000;
    font-weight: 700;
    width: 100%;
    background-color: transparent;
  }
  .login-btn-filed {
    width: 100%;
    height: 55px;
    text-align: center;
    line-height: 55px;
    background-color: #e9531d;
    color: #ffffff;
    border-radius: 7px;
    font-size: 20px;
    cursor: pointer;
  }
  @media screen and (max-width: 802px) {
    .login-section {
      width: 75%;
    }
    @media screen and (max-width: 504px) {
      .login-section {
        width: 90%;
      }
      .login-head {
        font-size: 30px !important;
      }
      .login-line {
        width: 100%;
        background-color: #dadada !important;
        height: 1px;
      }
    }
  }
  