// Fees starts

.dentist-image-box {
  display: flex;
  align-items: center;
}
.fees-heading {
  text-align: center;
  span {
    margin-top: 15px;
    display: inline-block;
    color: #6b7280;
    font-size: 15px;
    line-height: 24px;
  }
}
.mbl-fees-card {
  border: 1px solid rgb(189, 189, 189);
  box-shadow: 0 0 2 px #eee;
  padding: 20px;
  width: 90%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  .mbl-appoi-head {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: black;
    span {
      color: #e9531d;
    }
  }
  .mbl-vc-img {
    width: 100%;
    height: 320px;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;

      object-fit: cover;
    }
  }
}
.price-fee-head {
  color: #111827;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 20px;
  .price-colored {
    color: #e9531d;
    display: inline-block;
    font-size: 30px !important;
    font-weight: 900;
  }
}
.mbl-fee-text {
  margin-top: 15px;
  color: #404048;
  font-size: 16px;
  line-height: 30px;
}
.mbl-fee-text2 {
  margin-top: 15px;
  color: #404048;
  font-size: 16px;
  line-height: 30px;

  .price-colored {
    color: #e9531d !important;
    font-weight: 500;
  }
}
.mbl-fee-btn {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  background-color: rgba(233, 83, 29, 0.2);
  color: #e9531d;
  height: 50px;
  border-radius: 7px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.fees-statement {
  margin-top: -60px;
}

@media screen and (max-width: 991px) {
  #desktop-fees {
    display: none;
  }
  #mobile-fees {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .fees-statement {
    margin-top: 20px;
  }
}

@media screen and (max-width: 550px) {
  .mbl-vc-img {
    height: 270px !important;
  }
}
