
.nav-link{
    img{
        margin-top: -3px;
    }
}

.dropdown-element{

    position:relative;

    ul{
        display: none;
        position: absolute;
        width: 100%;
        background: white;
        z-index:2;
        min-width: 150px;
        padding-top:23px;

        li{
            a{
                border-left: 1px solid rgba(64,64,72,.2);
                border-right: 1px solid rgba(64,64,72,.2);
                padding:5px 10px;
                display: block;
                color: $default-text-color;
                &:hover{
                    color:$lighter-text-color;
                    // text-decoration: underline;
                    background-color: $secondary-color;
                }
            }

            &:last-child{
                a{
                    text-decoration: underline;
                    font-size: 0.8rem;
                    color: $lighter-text-color;
                    border-bottom: 1px solid rgba(64,64,72,.2);
                }
            }
        }
    }

    &:hover{
        ul{
            @media (min-width: 992px) {
                display: block;
            }
        }
    }
}







.navbar-brand{
    margin-right:0;
}

#header {
    background: #fff;
    border-bottom: 1px solid rgba(64, 64, 72, 0.2);


    @include max(992px) {
        .container {
            max-width: 100%;
            padding: 0 15px;
        }
        .navbar-collapse {
            position: absolute;
            top: 85px;
            right: 0;
            left: auto;
            bottom: 0;
            background: #fff;
            width: 50%;
            height: 100vh;
            z-index: 999;
            right: -9999px;
            transition: right .2s ease;
            @include max(575px) {
                width: 60%;
            }
            @include max(360px) {
                top: 85px;
            }
        }
        .navbar-collapse.show {
            right: -10px;
            @include max(575px) {
                right: -20px;
                top: 85px;
            }
        }
        .no-show {
            display: none !important;
        }
    }
    @include max(390px) {
        .navbar-brand {
            img {
                width: 150px;
            }
        }
    }
    .navbar-nav {
        .nav-item {

            &.active{
                .nav-link{
                    color:$primary-color;
                    opacity: 1;

                }
            }

            .nav-link {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #25242B;
                opacity: 0.75;
                font-family: 'Lato', sans-serif;
                padding: 0.5rem 1.5rem;
                @include max(992px) {
                    padding: 15px 25px;
                    border-bottom: 1px solid rgba(64, 64, 72, 0.2);
                }
                @include max(575px) {
                    padding: 15px 20px;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }
    .mob-new {
        .nav-item {
            .nav-link {
                border: none;
                @include max(575px) {
                    padding: 10px 20px;
                }
            }
        }
    }
    .log-detail {
        @include max(992px) {
            display: none !important;
        }
        li {
            margin: 0 10px 0 0;

            &:first-child {
                #nav-call-button {
                    background: white;
                    display: block;
                    border-radius: 10px;
                    border: 1px solid $primary-border-color;
                    padding: 6.5px;
                }
            }
            &:last-child {
                margin: 0;
            }
            .dropdown {
                .btn {
                    background: white;
                    border:1px solid $primary-border-color;
                    border-radius: 8px;
                    padding: 5px;
                    font-weight: 300;
                    font-size: 1rem;
                    height: 40px;
                    color: $primary-color;
                    font-family: 'Lato', sans-serif;
                    display: flex;
                    align-items: center;
                    &::after {
                        display: none;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                    img {
                        margin: 0;
                        max-height: 25px;
                    }
                }
            }
        }
    }
    .dropdown {
        .dropdown-menu {
            left: -66px;
            top: 59px;
            background: #FFFFFF;
            border: 1px solid #D8D8D9;
            box-shadow: 0px 10px 56px rgba(0, 0, 0, 0.03) 0;
            border-radius: 0px 0px 5px 5px;
            padding: 15px;
            @include max(992px) {
                left: -50px;
            }
            li {
                margin: 0;
                .dropdown-item {
                    padding: 10px 0;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: -0.0041em;
                    color: #282A2D;
                    font-weight: 400;
                    border-bottom: 1px solid #D8D8D9;
                    &:hover {
                        background: none;
                    }
                }
                &:last-child {
                    .dropdown-item {
                        border: none;
                        padding: 10px 0 0;
                    }
                }
            }
        }
    }
}
.cell-block {
    display: flex;
    align-items: center;
    .dropdown {
        .dropdown-toggle {
            &:after {
                display: none;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .navbar-toggler {
        padding: 0;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}
@include max(992px) {
    #navicon {
        position: relative;
        width: 38px;
        height: 35px;
        padding: 5px;
        border: 1px solid #E9531D;
        border-radius: 5px;
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 55%;
            background: #d3531a;
            border-radius: 9px;
            opacity: 1;
            left: auto;
            right: 7px;
            margin: 0 auto;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
            &:nth-child(1) {
                top: 9px;
            }
            &:nth-child(2) {
                top: 15px;
                width: 40%;
            }
            &:nth-child(3) {
                top: 21px;
            }
        }
    }
    #navicon.open {
        span {
            &:nth-child(1) {
                top: 15px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                top: 15px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }
    }
}
