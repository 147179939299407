

.sm-two{
  h4{
    font-size: 17px;
    font-weight: 500;
    margin-left: 5px;
  }
}

.search-controls{
  .btn-filter{
    position: relative;
    .indicator {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: red;
      color: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
    }
  }
  .select-all-filter,
  .clear-filter{
    width: 100%;
    &:hover{
      color:black;
    }
  }

  #filter-dropdown{
    label:hover{
      cursor: pointer;
      color:black;
    }
  }
}

.detail-message{
  font-weight: 700;
  font-size: 1.2rem;
}



* {
    box-sizing: border-box;
  }

.appointment-form{
  max-width: 1100px;
  margin: 0 auto;
}

.btn-search,
.btn-filter{
  align-self: end;
  width: 100%;
}

.btn-filter{
    float: right;
    margin-top: 0px;
    min-height: 40px;
    background: #fdeee8;
    border: 1px solid #bc6344;
    color: #e9531d;
    text-align: center;
    width: 100%; 
}

    // width: 160px;
    // max-width: 100px;

.wheelchair{
  label{
    align-self:center;
    padding-right:5px;
  }


  .d-flex{
    height:50px;
    text-align: center;
  }
}

input[name=postcode]{
  text-transform: uppercase
}


.submit-btn-input {
  border: 1px solid #e9531d;
  height: 38px;
  line-height: 33px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #fdeee8;
  color: #e9531d;
  font-weight: 700;
  width: 100%;
}
.submit-btn-input:hover {
  background-color: #e9531d;
  color: #ffffff;
}


  .aWithD {
    margin-left: auto;
    margin-right: auto;
    // min-height: 50vh;
    background: white;
    border-radius: 10px;
    border: 1px solid #e9531d;
    label {
      color: #333333;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }

  }
  .aWithD .options {
    width: 100%;
    display: flex;
    font-size: 1rem;
    gap: 15px;
  }
  
  .aWithD .options .in {
    width: 30%;
  }
  .aWithD .options .in .input {
    margin-top: 5px;
  }
  .aWithD .options .distance {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .aWithD .options .distance select {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 19px;
    color: black;
    border: none;
    outline: none;
  }

  .input{
    input{
      width:100%;
    }
    padding:10px;
  }
  select.input{
      background-color:white;
  }

  .aWithD .options .in .input
  .aWithD .options .in .input select,
   {
    width: 100%;
  }

  .aWithD .options .in .input {
    height: 50px;
    border-radius: 5px;
    border: 1px solid grey;
  }
  .aWithD .options .in .input input {
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.2rem;
    color: black;
  }
  .aWithD .options .in .input:focus-within {
    border-color: #e9531d;
  }
  .wheelchair .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .wheelchair .input {
    padding: 5px;
  }
  .wheelchair .yn {
    width: 50%;
    height: 100%;
  }
  .wheelchair .yn a {
    width: 100%;
    height: 100%;
    color: black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .wheelchair .btnYes a {
    color: white;
    background: black;
  }
  .searchBtn {
    width: 15%;
    height: 6vh;
    margin-top: auto;
    border-radius: 5px;
    background: #e9531d;
  }
  .searchBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .searchBtn .sIcon {
    color: white;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchBtn .text {
    color: white;
  }
  
  .aWithD .checks {
    width: 100%;
    min-height: 12vh;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .aWithD .checks label {
    font-size: 0.8rem;
  }
  .aWithD .checks input {
    accent-color: #e9531d;
  }
  
  .btn-search {
    width: 100%;
    height: 50px;
    margin-top: auto;
    border-radius: 5px;
    background: #e9531d;
  }
  
  .btn-search button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
        border-radius:5px;

  }
  .btn-search .sIcon {
    color: white;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }



  @media screen and (min-width: 919px){
      .btn-search{
        max-width: 120px;
      }
  }
  
  @media screen and (min-width: 1200px){
      .btn-search.search,
      button[name=search]{
        max-width: 200px;
      }
  }
  


  @media screen and (max-width: 745px) {
    .wheelchair label {
      font-size: 0.7rem;
    }
  }
  
  @media screen and (max-width: 378px) {
    .aWithD {
      p {
        font-size: 1.2 rem !important;
      }
    }
  }
  .mbl-img-seach{
    display: none;
  }
  @media screen and (max-width: 918px) {
    .desk-img-seach{
      display: none;

    }
    .mbl-img-seach{
      display: block;
    }
    .search-bg {
      height: 60% !important;
      img{
        width: 100%;
       object-fit: cover;
      }
    }
  
    .aWithD .options {
      flex-direction: column;
    }
    .aWithD .options .in {
      width: 100%;
    }
    .wheelchair label {
      font-size: 0.8rem;
    }
    .aWithD .options .in .input {
      width: 100%;
      height: 50px;
    }
    .searchBtn {
      // display: none;
    }
    .btn-search {

      display: block;
      height: 50px;
    }
 
  

  }
  @media screen and (max-width: 692px) {
    .aWithD .checks {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  @media screen and (max-width: 1003px) {
    .aWithD {
      label {
        font-size: 14px;
      }
    }
  }
  
  .search-section {
      background-image: url("/assets/images/search-bg.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
  }
 
  .Search-heading {
    p {
      margin-bottom: 10px;
      color: #333333;
      font-weight: 500;
    }
  }
  
  .Search-cards {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .maintain-card {
    width: 81.5%;
    margin-left: auto;
    margin-right: auto;
  }
  

  .location,
  .away,
  .clock,
  .time {
    border-bottom: 1px solid #bdbdbd;
    margin-bottom: 7px;
    padding-bottom: 7px;
  
    span {
      color: #333333;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin-left: 3px;
    }
    div {
      color: #1d1d1d;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .practice-section {
    span {
      color: #333333;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin-left: 3px;
    }
  }
  
  .dental-record {
    color: #1d1d1d;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .booking-btn {
    margin-top: 25px;
    border: 1px solid #e9531d;
    height: 38px;
    line-height: 33px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    background-color: #fdeee8;
    color: #e9531d;
    font-weight: 700;
  }
  .booking-btn:hover {
  background-color: #e9531d;
  color: #ffffff;
  }



  
  .check-list {
    margin-left: 100px;
  }
  .location-card {
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    border-radius: 7px;
  }
  @media screen and (max-width: 918px) {
    .location-card {
      max-width: 400px;
      border: 1px solid #e0e0e0;
      background-color: #ffffff;
      border-radius: 7px;
      margin: 0 auto;
    }
  }
  
  .loader {
    animation: animName 2s linear infinite;
    transform-origin: center;
    margin: 20px;
  }
  
  @keyframes animName {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .rotate-circle-anim {
    display: flex;
    justify-content: center;
  }
  
  #btn-no {
    border-radius: 5px;
  }
  