// heading with optional first letter coloured
.heading-underline {
  font-weight: 700;
  text-align: center;
  font-size: 2.5rem;
  position: relative;
  display: inline-block;

  h1 {
    font-size: 2.5rem !important;
  }

  .first-letter {
    &::first-letter {
      color: $primary-color;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 10%;
    width: 80%;
    height: 5px;
    background-color: #e9531d;
    border-radius: 50px;
  }
}

.list-coloured {
  li {
    list-style: inside;
    &::marker {
      color: red; /* Colors just the disc marker */
    }
  }
}

.text-primary {
  color: $primary-color !important;
}

.primary-color {
  &:img,
  &:span,
  img,
  span {
    color: $primary-color !important;
  }

  color: $primary-color !important;
}

.bg-grey {
  background-color: #cfcfcf;
}
.bg-light {
  background-color: #fff7f4 !important;
}

.box-grey-bg {
  padding: 1rem;
  border-radius: 5px;
  background-color: #cfcfcf;
}

.img-rounded {
  border-radius: 15px;
}
