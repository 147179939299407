
.has-error {
	border-color: #dc3545;
	padding-right: calc(1.5em + .75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(.375em + .1875rem) center;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}


.btn.btn-cta{
	background-color: $primary-color;
	border: $primary-border-color;
	border-radius: 5px;
	color: #fff;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;

	&:hover{
		background-color: darken($primary-color, 20);
		border: darken($primary-border-color,20);
	}
}

.btn-black-full {
	float: right;
	margin-top: 0;
	min-height: 40px;
	text-align: center;
	width: 100%;
    min-width: 100px;	
	background-color: black;
	color: white;
}

a.btn-black-full {
	padding-top: 7px;
}