.container {
    .thanks_para {
      display: block;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: #404048;
      line-height: 25px;
    }
    .myaccount {
      color: black;
    }
  
    .thanks-heading {
      p {
        color: #e9531d;
        font-style: normal;
        font-weight: 900;
        font-size: 41px;
        text-align: center;
      }
    }
  }
  .detail-section::after {
    content: url("/assets/images//mask.webp");
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .detail-section {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 7px;
    margin-top: 40px;
    display: flex;
    position: relative;
  
    span {
      color: #1d1d1d;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
    }
    div {
      color: #333333;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
    .section2 {
      width: 30%;
    }
    .section1 {
      width: 30%;
    }
  }
  @media screen and (max-width: 768px) {
    .detail-section {
      flex-direction: column;
      .section2 {
        width: 100%;
      }
      .section1 {
        width: 100%;
      }

    }
    .detail-section::after {
      content: url(/assets/images/mbl-sign.webp);
      position: absolute;
      top: 7%;
      right: 8%;
    }
   
  }
  