.second-revivews{
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    

}
.rev-card{
    width: 49%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 30px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 2px #707070;
    margin-bottom: 35px;
    position: relative;
}
.rev-card::after{
    position: absolute;
    content: "";
    height: 40px;
    width: 40px; /* Set a width to maintain the aspect ratio or use 'auto' if preferred */
    right: 10px;
    top: 10px;
    background-image: url('/assets/images/svg-icons/quotes.svg');
    background-size: contain; /* This ensures the image fits within the dimensions, maintaining its aspect ratio */
    background-repeat: no-repeat;
    background-position: center;
}
    
.rev-top{
    display: flex;
    align-items: center;
}
.mars-text{
    // margin-left: 25px;
}
.myMarc{
    font-size: 15px;
    color: #1D1D1D;
    font-weight: 600;
}
.December{
    font-size: 13px;
    color: #676767;
}
.rev-texts{
    margin-top: 20px;
    font-size: 15px;
    color: #3E3E3E;
    font-weight: 500;
    padding-right: 30px;
}
.rev-stars{
    margin-top: 20px;
}

@media screen and (max-width:620px) {
    .second-revivews{
        width: 90%;
    }
    .rev-texts{
    padding-right: 0px !important;
}
    .rev-card{
        width: 100%;
    }
    
}

