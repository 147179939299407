label.terms{
  width: 100%;
  padding:0 0 5px;
  input{
    margin-right:10px;
  }
}

.adult-child {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  
  label {
    flex-basis: 47%;
    height: 45px;
    border-radius: 7px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;

    border: 1px solid #dcdcdc;

    span:first-of-type{
      padding-top: 4px;
    }

    input {
      visibility: hidden;
    }

    img{
      visibility: hidden;
    }

    &.active {
      border: 1px solid #e9531d;
      background-color: #fdeee8;
      img{
        visibility: visible;
      }
    }
  }
}


.form-box{

    > div{
      background-color: #fff;
      border-radius: 7px;
      border: 1px solid #e0e0e0;
    }

  .form-label{
    color: #404048;
    margin:0;
  }

  input[name=email_address]{
    // max-width: 50%;
  }

  @media (max-width: 767px){
    select[name=title]{
      max-width: 70px;
    }
    input[name=first_name],
    input[name=last_name],
    input[name=dob]{
      max-width:250px;
    }

    input[name=email_address]{
      // max-width: 100%;
    }

  }
  input[name=dob]{
    max-width: 200px;
  }

  select[name=title]{
    max-width: unset;
  }

  label.underline{
    text-decoration: underline $primary-color;
    text-underline-offset: 5px;
  }
  

}

.primary-link{
  color:$primary-color;
  text-decoration: underline;
}

.proceedtopay-btn {
  width: 180px;
  height: 37px;
  background-color: $primary-color;
  border:$primary-border-color;
  border-radius: 7px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  &:hover{
    background-color: darken($primary-color, 10%);
  }
}

.pay-form{
  max-width: 516px;
}

.detail-info-appointment {
    color:$lighter-text-color;
    background-color: #ffffff;
    border-radius: 7px;
    position: relative;
    border: 1px solid #e0e0e0;
    overflow: hidden;
  
  }
  .detail-info-appointment::after {
    content: url(/assets/images/shape-left.webp);
    position: absolute;
    left: 0;
    top: 5%;
  }
  .detail-info-appointment::before {
    content: url(/assets/images/shape-right.webp);
    position: absolute;
    right: 0;
    bottom: 5%;
  }
  .detail-info-card {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    color: $default-text-color;

    img{
      margin-top: -3px;
    }

    a{
      color: $default-text-color;
      cursor: pointer;
      &:hover{
        color:$primary-color;
      }
    }
  }
  .dateform {
    label {
      color: #7b7b7b;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
    }
    .date-input {
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      height: 45px;
      border-radius: 6px;
      border: 1px solid #dcdcdc;
      padding-left: 10px;
  
      input {
        width: 90%;
        height: 100%;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .behalf-of {
    color: #404048;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
  }
  .termsandcond {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    .check1term {
      a {
        color: #e9531d;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        text-decoration: underline;
      }
    }
  

    span {
      color: #4f4f4f;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .forget_password {
    text-align: right;
    a {
      color: #ff0000;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
    }
  }
  .login-btn-booking {
    p {
      color: #4f4f4f;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
    }
    button {
      width: 100%;
      border-radius: 7px;
      height: 45px;
      text-align: center;
      background-color: #e9531d;
      color: #ffffff;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 15px;
    }
  }
  
  .Guardians-detail {
    p {
      color: #25242b;
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
    }
  }
  .form-chil-detail {
    p {
      color: #25242b;
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
    }
  }
  .hide-the-form {
    display: none;
  }
  
  @media screen and (max-width: 853px) {
    .appointment-booking-form {
      flex-direction: column;
    }
    .detail-info-appointment::after {
      content: "";
      position: absolute;
      left: 0;
      top: 5%;
    }
    .detail-info-appointment::before {
      content: url(/assets/images/mbl-shape-right.webp);
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  

  @media screen and (max-width: 400px) {
    .adult-child {
      span {
        font-size: calc(1vw + 1vh + 0.3vmin);
      }
      img {
        width: 16px;
      }
    }
  }
  
  .justfortag {
    position: relative;
  }
  .justfortag::before {
    position: absolute;
    content: "";
    width: 17px;
    height: 17px;
    background-color: white;
    transform: rotate(45deg);
    bottom: -11px;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
  #booking-all-process {
    input:focus {
      box-shadow: none;
    }
    input:focus {
      border: 1px solid rgb(211, 210, 210);
    }
  }
  
#bookingthirdform,
  #bookingtwoform,
  #bookinglastform {
    display: none;
  }
  .error_message,
  .error_message2,
  .error_message3,
  .error_message4 {
    margin-top: 10px;
    font-size: 16px;
    color: #e9531d;
    font-weight: 500px;
    font-style: normal;
  }
  .error_message5 {
    margin-top: 5px;
    font-size: 14px;
    color: #e9531d;
    font-weight: 300px;
    font-style: normal;
  }

.dhx_qi_big_icon.icon_delete_all {
    color: #ff584c;
}
.dhx_qi_big_icon.icon_delete_all:hover {
  border: 1px solid rgba(255,88,76,.01);
  background-color: rgba(255,88,76,.3);
}