@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
}

input,
button {
  border: 0px;
}
input:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

button {
  display: inline-block;
  background-color: transparent;
}

body {
  font-weight: 400;
  font-family: sans-serif;
  background: #FAFBFF;
  overflow-x: hidden;
}

.heading-underline {
  font-weight: 700;
  text-align: center;
  font-size: 2.5rem;
  position: relative;
  display: inline-block;
}
.heading-underline h1 {
  font-size: 2.5rem !important;
}
.heading-underline .first-letter::first-letter {
  color: #e9531d;
}
.heading-underline::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 10%;
  width: 80%;
  height: 5px;
  background-color: #e9531d;
  border-radius: 50px;
}

.list-coloured li {
  list-style: inside;
}
.list-coloured li::marker {
  color: red; /* Colors just the disc marker */
}

.text-primary {
  color: #e9531d !important;
}

.primary-color {
  color: #e9531d !important;
}
.primary-color:img, .primary-color:span,
.primary-color img,
.primary-color span {
  color: #e9531d !important;
}

.bg-grey {
  background-color: #cfcfcf;
}

.bg-light {
  background-color: #fff7f4 !important;
}

.box-grey-bg {
  padding: 1rem;
  border-radius: 5px;
  background-color: #cfcfcf;
}

.img-rounded {
  border-radius: 15px;
}

.has-error {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.btn.btn-cta {
  background-color: #e9531d;
  border: #bc6344;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}
.btn.btn-cta:hover {
  background-color: #92310e;
  border: #713b29;
}

.btn-black-full {
  float: right;
  margin-top: 0;
  min-height: 40px;
  text-align: center;
  width: 100%;
  min-width: 100px;
  background-color: black;
  color: white;
}

a.btn-black-full {
  padding-top: 7px;
}

.btn-cta {
  background: #e9531d;
  border-radius: 10px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: auto;
}

.flexSwitchCheckDefault {
  font-size: 20px;
  font-weight: 400;
  color: #4f4f4f;
}

/* Custom Switch Color */
.form-check-input:checked {
  background-color: #e9531d !important;
  border-color: #e9531d !important;
}

/* For Bootstrap 5, targeting the switch's thumb when checked */
.form-check-input:checked + .form-check-label::before {
  background-color: #fff !important;
}

/* Additional styling to handle focus shadow to match the color */
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(233, 83, 29, 0.25) !important;
}

#vidModal .close span {
  padding: 0 10px 0 0;
}

.aspect-ratio-16-9 {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}
.aspect-ratio-16-9 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#book-now {
  overflow: hidden;
}

.slider-boxes img {
  height: auto;
}

#pills-home img {
  height: auto;
}

input.postcode {
  text-transform: uppercase;
}
input.postcode::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
input.postcode:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
input.postcode::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
input.postcode:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
input.postcode::placeholder {
  /* Recent browsers */
  text-transform: none;
}

.vid-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #e9531d;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9531d;
  border-radius: 10px;
  width: 300px;
  margin: 0 auto;
  font-family: "Lato", sans-serif;
}
.vid-btn span {
  margin: 0 10px 0 0;
}

.top-section {
  background: linear-gradient(to bottom right, #fffbf3 0%, #edfaff 50%, #ffffff 100%);
  background-size: 100%;
}
.top-section .bo-btn {
  background: #e9531d;
  border-radius: 10px;
  padding: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
  text-decoration: none;
}
.top-section h2 {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}
.top-section h2 span {
  font-weight: 900;
  color: #e9531d;
}
@media (max-width: 1200px) {
  .top-section h2 {
    font-size: 14px;
    line-height: 16px;
  }
}
.top-section h1 {
  text-align: center;
}
.top-section h1 span {
  color: #e9531d;
}
.top-section .pricing-box {
  background-color: #fffbfa !important;
  box-shadow: 0px 2px 23px rgba(62, 73, 84, 0.04) !important;
}
.top-section .box {
  height: 100%;
  padding: 30px 40px;
  background: #ffffff;
  border: 1px solid #e9531d;
  border-radius: 12px;
}
.top-section .box .treatment-price {
  font-size: 42px;
  font-weight: 600;
}
.top-section .box .klarna-text {
  color: #4f4f4f;
  font-size: 20px;
  font-weight: medium;
}
.top-section .box .klarna-img {
  height: 23px;
  background-color: #ffb3c7;
  border-radius: 7px;
  width: 62px;
  padding: 5px;
  margin-bottom: 5px;
}
.top-section .box .cp-img {
  height: 25px;
  background-color: #b2fce4;
  border-radius: 7px;
  width: 80px;
  padding: 5px;
  margin-bottom: 5px;
}
.top-section .box .consultation-title {
  font-size: 28px;
  text-transform: unset;
  margin-right: 7px;
  font-weight: 600;
}
.top-section .box .consultation-subtitle {
  font-size: 20px;
  margin-top: 10px;
}
.top-section .box .consultation-img {
  margin-right: 10px;
}
.top-section .box .consultation-text {
  font-size: 20px;
  font-weight: medium;
}
.top-section .box .terms-text {
  font-size: 15px;
  color: 999999;
}
@media (max-width: 1200px) {
  .top-section .box {
    padding: 30px 25px;
  }
  .top-section .box .klarna-text {
    font-size: 18px;
  }
  .top-section .box .treatment-price {
    font-size: 32px;
    font-weight: 600;
  }
  .top-section .box .terms-text {
    font-size: 12.5px;
  }
  .top-section .box .consultation-img {
    max-height: 48px;
    max-width: 48px;
  }
  .top-section .box .consultation-text {
    font-size: 18px;
  }
  .top-section .box .consultation-title {
    font-size: 29px;
  }
  .top-section .box .consultation-subtitle {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .top-section .box .klarna-img {
    height: 18px;
    width: 40px;
    margin-bottom: 2px;
    border-radius: 5px;
  }
  .top-section .box .cp-img {
    height: 18px;
    width: 52px;
    padding: 4px;
    margin-bottom: 2px;
    border-radius: 5px;
  }
  .top-section .box .consultation-title {
    font-size: 26px;
  }
  .top-section .box .treatment-price {
    font-size: 26px;
  }
  .top-section .box .consultation-subtitle {
    font-size: 15px;
    margin-top: 12px;
    margin-left: 2px;
  }
  .top-section .box .consultation-text {
    font-size: 11px;
  }
  .top-section .box .klarna-text {
    font-size: 13px;
  }
  .top-section .box .terms-text {
    font-size: 11px;
  }
  .top-section .box .consultation-img {
    max-height: 36px;
    max-width: 36px;
    margin-right: 3px;
  }
}
@media (max-width: 575px) and (max-width: 392px) {
  .top-section .box .consultation-img {
    max-height: 27px;
    max-width: 27px;
    margin-right: 1px;
  }
}
.top-section .box h2,
.top-section .box h3 {
  font-weight: 900;
  font-size: 32px;
  line-height: 34px;
  color: #25242b;
  text-align: left;
}
@media (max-width: 1400px) {
  .top-section .box h2,
  .top-section .box h3 {
    font-size: 25px;
    line-height: 30px;
  }
}
@media (max-width: 1200px) {
  .top-section .box h2,
  .top-section .box h3 {
    font-size: 22px;
    line-height: 16px;
  }
}
@media (max-width: 575px) {
  .top-section .box h2,
  .top-section .box h3 {
    font-size: 20px;
    line-height: 26px;
  }
}
.top-section .box .search {
  border: 1px solid #e9531d;
  border-radius: 10px;
  padding: 0 0 0 60px;
  width: 100%;
  font-weight: 500;
  font-size: 24px !important;
  line-height: 50px !important;
  color: #e9531d;
  background: url(/assets/images/svg-icons/search-orange.svg) center left 15px no-repeat;
}
.top-section .box .search::placeholder {
  color: #e9531d;
}
@media (max-width: 575px) {
  .top-section .box .search {
    font-size: 15px;
    line-height: 18px;
  }
}
.top-section .box .small h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4f4f4f;
  margin: 0;
}
@media (max-width: 1367px) {
  .top-section .box .small h3 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 992px) {
  .top-section .box .small h3 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 575px) {
  .top-section .box .small h3 {
    font-size: 14px;
    line-height: 16px;
  }
}
.top-section .box .switch {
  width: 50px;
}
@media (min-width: 992px) {
  .top-section .box .switch {
    width: 40px;
  }
}
@media (min-width: 1367px) {
  .top-section .box .switch {
    width: 70px;
  }
}
.top-section .box a {
  padding: 0px 15px;
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #4f4f4f;
}
@media (max-width: 575px) {
  .top-section .box a {
    font-size: 12px;
    line-height: 14px;
  }
}
.top-section .box p {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #4f4f4f;
  margin: 0 0 30px;
}
@media (max-width: 575px) {
  .top-section .box p {
    font-size: 18px;
    line-height: 20px;
  }
}
.top-section .box .img-text {
  display: flex;
  align-items: center;
  margin: 0 0 30px;
}
@media (max-width: 1280px) {
  .top-section .box .img-text {
    margin: 0 0 25px;
  }
}
@media (max-width: 767px) {
  .top-section .box .img-text {
    flex-direction: column;
    text-align: center;
    margin: 0 0 25px;
  }
}
.top-section .box .img-text h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: #404048;
  margin: 0;
  padding: 0 0 0 1.5rem;
}
@media (max-width: 767px) {
  .top-section .box .img-text h3 {
    padding: 0;
    font-size: 15px;
    line-height: 17px;
  }
}
.top-section .box .b-text {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top-section .box .b-text h4 {
  font-weight: 900;
  font-size: 52px;
  line-height: 62px;
  text-align: right;
  color: #e9531d;
}
@media (max-width: 575px) {
  .top-section .box .b-text h4 {
    font-size: 42px;
    line-height: 44px;
  }
}
.top-section .bg {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.top-section .bg:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 0px;
  width: 136px;
  height: 170px;
  background-image: url("/assets/images/icon13.png"); /* Logo image */
  background-size: cover;
  background-position: center;
  z-index: -1;
}
@media (max-width: 1200px) {
  .top-section .bg:before {
    width: 128px;
    height: 165px;
  }
}
@media (max-width: 767px) {
  .top-section .bg:before {
    width: 108px;
    height: 135px;
  }
}

.help-area h2 {
  font-weight: 800;
  font-size: 42px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  margin: 0 0 50px;
  position: relative;
}
.help-area h2::before {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: -30px;
  width: 200px;
  height: 5px;
  background: #e9531d;
  content: "";
  margin: 0 auto;
}
@media (max-width: 992px) {
  .help-area h2::before {
    width: 150px;
  }
}
@media (max-width: 1200px) {
  .help-area h2 {
    font-size: 38px;
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .help-area h2 {
    font-size: 30px;
    line-height: 32px;
  }
}
.help-area p {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #404048;
}
.help-area .box {
  padding: 40px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 250px;
}
@media (max-width: 1200px) {
  .help-area .box {
    padding: 20px 10px;
    height: 200px;
  }
}
@media (max-width: 992px) {
  .help-area .box {
    margin: 0 0 1.5rem;
  }
}
@media (max-width: 767px) {
  .help-area .box {
    padding: 20px;
    flex-direction: inherit;
    height: auto;
  }
}
@media (max-width: 767px) {
  .help-area .box {
    padding: 10px;
    height: 75px;
  }
}
.help-area .box img {
  height: auto;
  margin: 0 0 30px;
}
@media (max-width: 1200px) {
  .help-area .box img {
    margin: 0 0 15px;
  }
}
@media (max-width: 767px) {
  .help-area .box img {
    margin: 0 10px 0 0;
    width: 25%;
  }
}
.help-area .box h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  margin: 0;
}
@media (max-width: 1367px) {
  .help-area .box h3 {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .help-area .box h3 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .help-area .box h3 {
    text-align: left;
  }
}

.foll-area-wrapper {
  position: relative;
}
.foll-area-wrapper > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.foll-area {
  padding: 4rem 0 6rem;
  height: 70vh;
  position: relative;
}
.foll-area.active {
  height: unset;
}
.foll-area.active img {
  transform: rotate(180deg);
}
.foll-area .container {
  overflow: hidden;
  height: 100%;
  -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
}
.foll-area .btn-show {
  position: absolute;
  bottom: 0;
}
@media (max-width: 1367px) {
  .foll-area {
    padding: 4rem 0 4rem;
  }
}
@media (max-width: 992px) {
  .foll-area {
    background-size: cover;
    padding: 2rem 0;
  }
}
@media (max-width: 575px) {
  .foll-area {
    padding: 2rem 5px;
  }
}
.foll-area h2 {
  font-weight: 300;
  font-size: 42px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #e9531d;
  margin: 0 0 35px;
  position: relative;
}
@media (max-width: 992px) {
  .foll-area h2 {
    font-size: 30px;
    line-height: 32px;
  }
}
.foll-area h2:before {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: -30px;
  width: 200px;
  height: 5px;
  background: #fff;
  content: "";
  margin: 0 auto;
}
.foll-area .link-box.active {
  height: auto;
}
.foll-area .locations-list ul {
  padding: 0;
  margin: 0;
}
.foll-area .locations-list ul li {
  margin: 10px 0;
  display: inline-block;
  text-align: center;
  width: 50%;
}
@media (min-width: 992px) {
  .foll-area .locations-list ul li {
    width: 25%;
  }
}
.foll-area .locations-list a {
  color: #fff;
  display: block;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 26px;
  padding: 1rem 0;
  text-transform: capitalize;
}
.foll-area .locations-list a:hover {
  color: #cccccc;
}
.foll-area .link-box {
  padding: 2.5rem 0 0;
}
@media (max-width: 1367px) {
  .foll-area .link-box {
    padding: 2.5rem 0 0;
  }
}
@media (max-width: 992px) {
  .foll-area .link-box {
    padding: 1.5rem 0 0;
  }
}
@media (max-width: 575px) {
  .foll-area .link-box {
    flex-wrap: wrap;
    padding: 1.5rem 2.5rem 0;
    height: 300px;
    overflow: hidden;
  }
}
.foll-area .link-box ul {
  padding: 0;
  margin: 0;
}
.foll-area .link-box ul li {
  margin: 10px 0;
}
.foll-area .link-box ul li a {
  display: block;
  padding: 1rem 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #ffffff;
}
@media (max-width: 1367px) {
  .foll-area .link-box ul li a {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 1200px) {
  .foll-area .link-box ul li a {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 992px) {
  .foll-area .link-box ul li a {
    font-size: 13px;
    line-height: 15px;
  }
}
@media (max-width: 767px) {
  .foll-area .link-box ul li a {
    font-size: 11px;
    line-height: 13px;
  }
}
@media (max-width: 575px) {
  .foll-area .link-box ul li a {
    font-size: 15px;
    line-height: 19px;
  }
}
.foll-area .btn-show {
  left: 50%;
  right: 50%;
  width: 130px;
  margin-left: -65px;
  text-align: center;
}
.foll-area .btn-show button {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.75;
  display: flex;
  align-items: center;
}
.foll-area .btn-show button img {
  margin: -3px 5px 0 0;
}
.foll-area .btn-show button:focus {
  color: #ffffff;
  opacity: 0.75;
}

@media (max-width: 992px) {
  .work-detail {
    padding: 0;
  }
}
@media (max-width: 575px) {
  .work-detail {
    padding: 0 5px;
  }
}
.work-detail h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 42px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  position: relative;
  margin: 0 0 60px;
}
@media (max-width: 992px) {
  .work-detail h2 {
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 50px;
    text-align: center;
  }
}
.work-detail h2:before {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: -25px;
  width: 100px;
  height: 4px;
  background: #e9531d;
  border-radius: 2px;
  content: "";
}
@media (max-width: 992px) {
  .work-detail h2:before {
    margin: 0 auto;
  }
}
.work-detail ul {
  padding: 0 15px 0 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 575px) {
  .work-detail ul {
    padding: 0;
  }
}
.work-detail ul:before {
  position: absolute;
  top: 0;
  left: 20px;
  right: auto;
  content: "";
  width: 1px;
  height: 90%;
  background: #e9531d;
  z-index: -1;
}
@media (max-width: 767px) {
  .work-detail ul:before {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.work-detail ul li {
  display: flex;
  align-items: flex-start;
  margin: 0 0 40px;
}
@media (max-width: 767px) {
  .work-detail ul li {
    margin: 0 0 20px;
  }
}
.work-detail ul li .img-hold {
  width: 10%;
}
.work-detail ul li .text-hold {
  padding: 0;
  width: 90%;
}
@media (max-width: 1200px) {
  .work-detail ul li .text-hold {
    padding: 0 0 0 10px;
  }
}
@media (max-width: 992px) {
  .work-detail ul li .text-hold {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .work-detail ul li .text-hold {
    padding: 15px;
    background: #ffffff;
    border: 1px solid #e9531d;
    border-radius: 8px;
    width: 100%;
  }
}
.work-detail ul li .text-hold h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #e9531d;
  margin: 0;
}
@media (max-width: 1200px) {
  .work-detail ul li .text-hold h3 {
    margin: 0 0 7px;
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 992px) {
  .work-detail ul li .text-hold h3 {
    display: flex;
    align-items: center;
  }
  .work-detail ul li .text-hold h3 img {
    margin: 0 7px 0 0;
  }
}
.work-detail ul li .text-hold p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #404048;
  margin: 0;
}
@media (max-width: 1200px) {
  .work-detail ul li .text-hold p {
    font-size: 16px;
    line-height: 22px;
  }
}

.three-block h2,
h2.underline {
  font-weight: 800;
  font-size: 42px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  margin: 0 0 60px;
  position: relative;
}
@media (max-width: 992px) {
  .three-block h2,
  h2.underline {
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 40px;
  }
}
.three-block h2:before,
h2.underline:before {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: -30px;
  width: 150px;
  height: 4px;
  background: #e9531d;
  border-radius: 2px;
  content: "";
  margin: 0 auto;
}

.three-block {
  position: relative;
}
.three-block:before {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  content: "";
  bottom: auto;
  background: url(../images/icon13.webp) no-repeat;
  width: 160px;
  height: 200px;
  background-size: 100%;
}
@media (max-width: 1200px) {
  .three-block:before {
    width: 120px;
  }
}
@media (max-width: 575px) {
  .three-block:before {
    display: none;
  }
}
.three-block .boxs {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  text-align: center;
  padding: 1rem;
  height: 260px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  display: flex;
}
@media (max-width: 992px) {
  .three-block .boxs {
    width: 60%;
    margin: 0 auto 20px;
  }
}
@media (max-width: 992px) {
  .three-block .boxs {
    width: 80%;
    margin: 0 auto 20px;
  }
}
@media (max-width: 992px) {
  .three-block .boxs {
    width: 100%;
  }
}
.three-block .boxs img {
  margin: 0 0 20px;
}
.three-block .boxs p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #404048;
}
@media (max-width: 1367px) {
  .three-block .boxs p {
    font-size: 14px;
    line-height: 22px;
  }
}

.video-block {
  padding: 0;
}
@media (max-width: 992px) {
  .video-block {
    padding: 0 0 2rem;
  }
}
@media (max-width: 575px) {
  .video-block {
    padding: 0 5px 2rem;
  }
}
.video-block h2 {
  font-weight: 800;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  text-align: left;
  margin: 0 0 35px;
  position: relative;
  text-align: center;
}
.video-block h2 span {
  color: #e9531d;
}
.video-block h2:before {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: -20px;
  width: 100px;
  height: 4px;
  background: #e9531d;
  border-radius: 2px;
  content: "";
  margin: 0 auto;
}
.video-block .vid-block {
  position: relative;
}
.video-block .vid-block img {
  height: auto;
}
@media (max-width: 992px) {
  .video-block .vid-block .desk {
    margin: 0;
  }
}
@media (max-width: 992px) {
  .video-block .vid-block {
    text-align: center;
  }
}
.video-block .vid-block .capation {
  position: absolute;
  top: -15%;
  left: -6.5rem;
  right: 0;
  bottom: 0;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1367px) {
  .video-block .vid-block .capation {
    left: -4.5rem;
  }
}
@media (max-width: 992px) {
  .video-block .vid-block .capation {
    top: 0;
    left: 0;
  }
}
.video-block .vid-text {
  padding: 2.5rem 3rem 0 0rem;
}
@media (max-width: 1200px) {
  .video-block .vid-text {
    padding: 0;
  }
}
.video-block .vid-text h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 50px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  text-align: left;
  margin: 0 0 35px;
  position: relative;
}
@media (max-width: 1200px) {
  .video-block .vid-text h2 br {
    display: none;
  }
}
.video-block .vid-text h2 span {
  color: #e9531d;
}
.video-block .vid-text h2:before {
  position: absolute;
  top: auto;
  right: auto;
  left: 0;
  bottom: -20px;
  width: 100px;
  height: 4px;
  background: #e9531d;
  border-radius: 2px;
  content: "";
}
.video-block .vid-text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #404048;
  margin: 0 0 20px;
}
@media (max-width: 1200px) {
  .video-block .vid-text p {
    margin: 0 0 10px;
    line-height: 22px;
  }
}

.mo-block {
  padding: 15rem 0 15rem;
  background: url(../images/banner03.webp) no-repeat center top;
  background-size: 100%;
  position: relative;
  z-index: 99;
}
@media (max-width: 1800px) {
  .mo-block {
    padding: 13rem 0 13rem;
  }
}
@media (max-width: 1700px) {
  .mo-block {
    padding: 12rem 0 12rem;
  }
}
@media (max-width: 1600px) {
  .mo-block {
    padding: 11rem 0 11rem;
  }
}
@media (max-width: 1500px) {
  .mo-block {
    padding: 10rem 0 10rem;
  }
}
@media (max-width: 1400px) {
  .mo-block {
    padding: 9rem 0 9rem;
  }
}
@media (max-width: 1367px) {
  .mo-block {
    padding: 7rem 0 7rem;
  }
}
@media (max-width: 1200px) {
  .mo-block {
    padding: 6rem 0 6rem;
  }
}
@media (max-width: 992px) {
  .mo-block {
    padding: 2rem 0;
    background: #fdeee8;
  }
}
@media (max-width: 575px) {
  .mo-block {
    padding: 2rem 5px;
  }
}
.mo-block h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 42px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #000000;
  margin: 0 0 30px;
  text-align: center;
}
@media (max-width: 992px) {
  .mo-block h2 {
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 20px;
  }
}
.mo-block p {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: #404048;
  margin: 0 0 60px;
  text-align: center;
}
@media (max-width: 1150px) {
  .mo-block p {
    margin: 0 0 20px;
  }
}
@media (max-width: 992px) {
  .mo-block p {
    text-align: left;
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .mo-block p {
    font-size: 16px;
    line-height: 18px;
  }
}
.mo-block .mo-box {
  background: #ffffff;
  border-radius: 20px;
  height: 160px;
}
@media (min-width: 992px) {
  .mo-block .mo-box {
    height: 200px;
    margin: 0 0 20px;
  }
}
@media (min-width: 1200px) {
  .mo-block .mo-box {
    height: 180px;
    padding: 15px;
  }
}
.mo-block .mo-box:hover {
  background: #e9531d;
}
.mo-block .mo-box:hover p {
  color: #fff;
}
.mo-block .mo-box:hover h3 {
  color: #fff;
}
.mo-block .mo-box h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  margin: 0 0 20px;
}
@media (max-width: 1200px) {
  .mo-block .mo-box h3 {
    margin: 0 0 15px;
  }
}
@media (max-width: 992px) {
  .mo-block .mo-box h3 {
    margin: 0 0 10px;
  }
}
@media (max-width: 575px) {
  .mo-block .mo-box h3 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 370px) {
  .mo-block .mo-box h3 {
    font-size: 14px;
    line-height: 16px;
  }
}
.mo-block .mo-box p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #404048;
  margin: 0;
  text-align: left;
}
@media (max-width: 992px) {
  .mo-block .mo-box p {
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .mo-block .mo-box p {
    line-height: 22px;
  }
}
@media (max-width: 575px) {
  .mo-block .mo-box p {
    line-height: 18px;
    font-size: 14px;
  }
}
@media (max-width: 370px) {
  .mo-block .mo-box p {
    line-height: 16px;
    font-size: 13px;
  }
}

.blog-area {
  background: #fff;
  position: relative;
  z-index: 1;
}
.blog-area h2 {
  font-weight: 800;
  font-size: 42px;
  line-height: 44px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  margin: 0 0 40px;
  position: relative;
}
@media (max-width: 992px) {
  .blog-area h2 {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }
}
.blog-area h2 span {
  color: #e9531d;
}
.blog-area h2:after {
  position: absolute;
  bottom: -25px;
  left: 50%;
  width: 140px;
  height: 4px;
  background: #e9531d;
  border-radius: 2px;
  content: "";
  transform: translateX(-50%);
}
@media (max-width: 992px) {
  .blog-area h2:after {
    left: 50%;
    transform: translateX(-50%);
  }
}
.blog-area p {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #404048;
  margin: 0 0 40px;
}
@media (max-width: 992px) {
  .blog-area .blog-block {
    display: flex;
    max-width: 100%;
    overflow: auto;
  }
}
.blog-area .blog-block .block {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 100%;
}
@media (max-width: 1200px) {
  .blog-area .blog-block .block {
    margin: 0 0 10px;
  }
}
@media (max-width: 992px) {
  .blog-area .blog-block .block {
    margin: 0 10px 0;
    width: 50%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .blog-area .blog-block .block {
    width: 70%;
  }
}
@media (max-width: 575px) {
  .blog-area .blog-block .block {
    width: 85%;
  }
}
.blog-area .blog-block .block img {
  width: 100%;
}
.blog-area .blog-block .text {
  padding: 15px 15px;
}
.blog-area .blog-block .text h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #e9531d;
  margin: 0 0 10px;
}
@media (max-width: 1367px) {
  .blog-area .blog-block .text h3 {
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 7px;
  }
}
.blog-area .blog-block .text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #404048;
  margin: 0;
}
@media (max-width: 1367px) {
  .blog-area .blog-block .text p {
    font-size: 14px;
    line-height: 22px;
  }
}

.dp-area {
  padding: 0 0 3rem;
}
@media (max-width: 992px) {
  .dp-area {
    padding: 0 0 3rem;
  }
}
.dp-area .box {
  background: #ffffff;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  border-radius: 20px;
  padding: 3rem 0;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 1200px) {
  .dp-area .box {
    padding: 2rem 0;
  }
}
@media (max-width: 992px) {
  .dp-area .box {
    padding: 1.5rem 0;
  }
}
@media (max-width: 767px) {
  .dp-area .box {
    padding: 0.7rem 0;
  }
}
@media (max-width: 575px) {
  .dp-area .box {
    padding: 40px 15px;
    border-radius: 10px;
  }
}
.dp-area .box:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  background: url(../images/banner04.webp) no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100%;
  z-index: -1;
}
@media (max-width: 575px) {
  .dp-area .box:before {
    background: url(../images/banner05.webp) no-repeat;
    background-size: cover;
  }
}
.dp-area .box h3,
.dp-area .box h2 {
  font-weight: 700;
  font-size: 52px;
  line-height: 54px;
  text-align: center;
  color: #1d1d1d;
  margin: 0 0 30px;
}
.dp-area .box h3 span,
.dp-area .box h2 span {
  color: #e9531d;
}
@media (max-width: 1200px) {
  .dp-area .box h3,
  .dp-area .box h2 {
    font-size: 40px;
    line-height: 44px;
    margin: 0 0 20px;
  }
}
@media (max-width: 992px) {
  .dp-area .box h3,
  .dp-area .box h2 {
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .dp-area .box h3,
  .dp-area .box h2 {
    font-size: 20px;
    line-height: 22px;
    margin: 0 0 10px;
  }
}
@media (max-width: 575px) {
  .dp-area .box h3,
  .dp-area .box h2 {
    font-size: 32px;
    line-height: 46px;
    margin: 0 0 20px;
  }
}
.dp-area .box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #484848;
  margin: 0 0 40px;
}
@media (max-width: 1200px) {
  .dp-area .box p {
    margin: 0 0 20px;
  }
}
@media (max-width: 992px) {
  .dp-area .box p {
    margin: 0 0 15px;
    font-size: 12px;
    line-height: 14px;
  }
}
@media (max-width: 575px) {
  .dp-area .box p {
    margin: 0 0 20px;
  }
}
.dp-area .box .bok-btn {
  background: #e9531d;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 15px 15px;
  border: none;
  width: 150px;
}
@media (max-width: 767px) {
  .dp-area .box .bok-btn {
    font-size: 14px;
    line-height: 16px;
  }
}
@media (max-width: 575px) {
  .dp-area .box .bok-btn {
    font-size: 18px;
    line-height: 25px;
    width: 100%;
  }
}

code {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #f5f5f5;
  color: #e9531d !important;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: monospace;
  white-space: nowrap;
}

.copy-btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  margin-left: 6px;
  opacity: 0.6;
  transition: opacity 0.2s, transform 0.2s;
}

.copy-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

.tooltip .tooltip-inner {
  text-align: left !important;
  white-space: normal !important;
  max-width: 200px;
}

.appointment-landing-card {
  background-color: white !important;
  border: 1px solid #f8f9fa !important;
  border-radius: 10px !important;
  padding: 50px !important;
  cursor: pointer !important;
}
@media (max-width: 575px) {
  .appointment-landing-card {
    padding: 25px !important;
  }
}

.nav-link img {
  margin-top: -3px;
}

.dropdown-element {
  position: relative;
}
.dropdown-element ul {
  display: none;
  position: absolute;
  width: 100%;
  background: white;
  z-index: 2;
  min-width: 150px;
  padding-top: 23px;
}
.dropdown-element ul li a {
  border-left: 1px solid rgba(64, 64, 72, 0.2);
  border-right: 1px solid rgba(64, 64, 72, 0.2);
  padding: 5px 10px;
  display: block;
  color: #212529;
}
.dropdown-element ul li a:hover {
  color: #5c5b60;
  background-color: #fdeee8;
}
.dropdown-element ul li:last-child a {
  text-decoration: underline;
  font-size: 0.8rem;
  color: #5c5b60;
  border-bottom: 1px solid rgba(64, 64, 72, 0.2);
}
@media (min-width: 992px) {
  .dropdown-element:hover ul {
    display: block;
  }
}

.navbar-brand {
  margin-right: 0;
}

#header {
  background: #fff;
  border-bottom: 1px solid rgba(64, 64, 72, 0.2);
}
@media (max-width: 992px) {
  #header .container {
    max-width: 100%;
    padding: 0 15px;
  }
  #header .navbar-collapse {
    position: absolute;
    top: 85px;
    right: 0;
    left: auto;
    bottom: 0;
    background: #fff;
    width: 50%;
    height: 100vh;
    z-index: 999;
    right: -9999px;
    transition: right 0.2s ease;
  }
}
@media (max-width: 992px) and (max-width: 575px) {
  #header .navbar-collapse {
    width: 60%;
  }
}
@media (max-width: 992px) and (max-width: 360px) {
  #header .navbar-collapse {
    top: 85px;
  }
}
@media (max-width: 992px) {
  #header .navbar-collapse.show {
    right: -10px;
  }
}
@media (max-width: 992px) and (max-width: 575px) {
  #header .navbar-collapse.show {
    right: -20px;
    top: 85px;
  }
}
@media (max-width: 992px) {
  #header .no-show {
    display: none !important;
  }
}
@media (max-width: 390px) {
  #header .navbar-brand img {
    width: 150px;
  }
}
#header .navbar-nav .nav-item.active .nav-link {
  color: #e9531d;
  opacity: 1;
}
#header .navbar-nav .nav-item .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #25242B;
  opacity: 0.75;
  font-family: "Lato", sans-serif;
  padding: 0.5rem 1.5rem;
}
@media (max-width: 992px) {
  #header .navbar-nav .nav-item .nav-link {
    padding: 15px 25px;
    border-bottom: 1px solid rgba(64, 64, 72, 0.2);
  }
}
@media (max-width: 575px) {
  #header .navbar-nav .nav-item .nav-link {
    padding: 15px 20px;
    font-size: 14px;
    line-height: 16px;
  }
}
#header .mob-new .nav-item .nav-link {
  border: none;
}
@media (max-width: 575px) {
  #header .mob-new .nav-item .nav-link {
    padding: 10px 20px;
  }
}
@media (max-width: 992px) {
  #header .log-detail {
    display: none !important;
  }
}
#header .log-detail li {
  margin: 0 10px 0 0;
}
#header .log-detail li:first-child #nav-call-button {
  background: white;
  display: block;
  border-radius: 10px;
  border: 1px solid #bc6344;
  padding: 6.5px;
}
#header .log-detail li:last-child {
  margin: 0;
}
#header .log-detail li .dropdown .btn {
  background: white;
  border: 1px solid #bc6344;
  border-radius: 8px;
  padding: 5px;
  font-weight: 300;
  font-size: 1rem;
  height: 40px;
  color: #e9531d;
  font-family: "Lato", sans-serif;
  display: flex;
  align-items: center;
}
#header .log-detail li .dropdown .btn::after {
  display: none;
}
#header .log-detail li .dropdown .btn:focus {
  box-shadow: none;
}
#header .log-detail li .dropdown .btn img {
  margin: 0;
  max-height: 25px;
}
#header .dropdown .dropdown-menu {
  left: -66px;
  top: 59px;
  background: #FFFFFF;
  border: 1px solid #D8D8D9;
  box-shadow: 0px 10px 56px rgba(0, 0, 0, 0.03) 0;
  border-radius: 0px 0px 5px 5px;
  padding: 15px;
}
@media (max-width: 992px) {
  #header .dropdown .dropdown-menu {
    left: -50px;
  }
}
#header .dropdown .dropdown-menu li {
  margin: 0;
}
#header .dropdown .dropdown-menu li .dropdown-item {
  padding: 10px 0;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.0041em;
  color: #282A2D;
  font-weight: 400;
  border-bottom: 1px solid #D8D8D9;
}
#header .dropdown .dropdown-menu li .dropdown-item:hover {
  background: none;
}
#header .dropdown .dropdown-menu li:last-child .dropdown-item {
  border: none;
  padding: 10px 0 0;
}

.cell-block {
  display: flex;
  align-items: center;
}
.cell-block .dropdown .dropdown-toggle:after {
  display: none;
}
.cell-block .dropdown .dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}
.cell-block .navbar-toggler {
  padding: 0;
}
.cell-block .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 992px) {
  #navicon {
    position: relative;
    width: 38px;
    height: 35px;
    padding: 5px;
    border: 1px solid #E9531D;
    border-radius: 5px;
  }
  #navicon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 55%;
    background: #d3531a;
    border-radius: 9px;
    opacity: 1;
    left: auto;
    right: 7px;
    margin: 0 auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #navicon span:nth-child(1) {
    top: 9px;
  }
  #navicon span:nth-child(2) {
    top: 15px;
    width: 40%;
  }
  #navicon span:nth-child(3) {
    top: 21px;
  }
  #navicon.open span:nth-child(1) {
    top: 15px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  #navicon.open span:nth-child(2) {
    opacity: 0;
  }
  #navicon.open span:nth-child(3) {
    top: 15px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}
.review-area .slider-box .more {
  color: #ee764b;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 992px) {
  .review-area {
    padding: 3rem 0 7rem;
  }
}
@media (max-width: 575px) {
  .review-area {
    padding: 3rem 5px 7rem;
  }
}
.review-area h2 {
  font-weight: 800;
  font-size: 42px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  margin: 0 0 40px;
  position: relative;
}
@media (max-width: 992px) {
  .review-area h2 {
    font-size: 30px;
    line-height: 32px;
  }
}
.review-area h2:before {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: -25px;
  width: 140px;
  height: 4px;
  background: #E9531D;
  border-radius: 2px;
  content: "";
  margin: 0 auto;
}
.review-area p {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #404048;
  margin: 0 0 40px;
}
.review-area .slider-area {
  padding: 0;
}
.review-area .slider-area .slider-box {
  background: #FFFFFF url(../images/img23.webp) no-repeat right 21px top 18px;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
  padding: 1rem;
}
.review-area .slider-area .slider-box h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}
.review-area .slider-area .slider-box h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #404040;
}
.review-area .slider-area .slider-box p {
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  margin: 0;
}
@media (max-width: 1367px) {
  .review-area .slider-area .slider-box p {
    font-size: 14px;
  }
}
.review-area .slider-area .slider-box p.h3 {
  color: #404040;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  font-style: italic;
}
.review-area .slider-area .slider-box p.h4 {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  text-transform: capitalize;
}
.review-area .slider-area .glide__bullets {
  bottom: -4rem;
}
.review-area .slider-area .glide__bullets .glide__bullet {
  background: #e6e6e6;
  width: 15px;
  height: 15px;
  box-shadow: none;
  border: none;
  margin: 0 10px;
  position: relative;
}
.review-area .slider-area .glide__bullets .glide__bullet--active {
  background: #E9531D;
}
.review-area .slider-area .glide__bullets .glide__bullet--active::before {
  position: absolute;
  top: -5px;
  right: 0;
  left: -5px;
  bottom: 0;
  content: "";
  width: 25px;
  height: 25px;
  border: 1.5px solid #E9531D;
  border-radius: 100%;
}

#footer {
  background: #404048;
  margin: 0;
}
#footer .con-info a,
#footer ul a {
  display: inline-block;
  padding: 4px 0;
  margin: 0;
  color: #d3d3d3;
}
#footer .con-info a {
  padding-right: 15px;
  font-size: 1.2rem;
}
#footer .con-info.large a {
  margin-top: 15px;
  font-size: 1rem;
  color: white;
}
#footer .footer-logo a {
  display: block;
}
#footer .footer-logo a img {
  width: auto;
  height: 35px;
}
#footer .con-info {
  margin: 0 0 40px;
}
@media (max-width: 1367px) {
  #footer .con-info {
    margin: 0 0 20px;
  }
}
@media (max-width: 992px) {
  #footer .con-info {
    margin: 0 0 30px;
  }
  #footer .con-info h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 0 0 20px;
  }
}
@media (max-width: 575px) {
  #footer .con-info h2 {
    font-size: 20px;
    line-height: 24px;
  }
}
#footer .con-info h3 span {
  margin: 0 15px 0 0;
}
@media (max-width: 1367px) {
  #footer .con-info h3 span {
    margin: 0 10px 0 0;
  }
}
#footer .con-info h3 span img {
  height: 0.8em;
  width: auto;
}
#footer .con-info h3 a {
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #FFFFFF;
}
@media (max-width: 1367px) {
  #footer .con-info h3 a {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 1200px) {
  #footer .con-info h3 a {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 992px) {
  #footer .con-info h3 a {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 992px) {
  #footer .s-link {
    padding: 30px 0;
    background: #404048;
    border-top: 1px solid #606060;
  }
  #footer .s-link ul {
    justify-content: center;
  }
}
#footer .s-link ul {
  display: flex;
}
#footer .s-link ul li {
  margin: 0 20px 0 0;
}
#footer .s-link ul li:last-child {
  margin: 0;
}
#footer .s-link ul li a {
  display: inline-block;
}
@media (max-width: 992px) {
  #footer .card-block {
    text-align: center;
    margin: 0 0 30px;
  }
  #footer .card-block h3 {
    justify-content: center;
  }
}
#footer .card-block > img {
  margin: -11px 3px 0 2px;
}
#footer .card-block h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  opacity: 0.8;
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}
#footer .card-block h3 img {
  margin: 0 10px 0 0;
}
#footer .page-bar {
  padding: 0.5rem 0 0 4rem;
}
#footer .page-bar h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #E9531D;
  margin: 0 0 20px;
}
#footer .page-bar ul li {
  margin: 0 0 15px;
}
#footer .page-bar ul li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  opacity: 0.8;
}
#footer .form-block h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #E9531D;
  margin: 0 0 20px;
}
#footer .form-block form input {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  opacity: 0.75;
  padding: 15px;
}
#footer .form-block form input:focus {
  box-shadow: none;
}
#footer .form-block form input::placeholder {
  color: #fff;
}
#footer .form-block form textarea {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  opacity: 0.75;
  padding: 15px;
  height: 160px;
}
#footer .form-block form textarea:focus {
  box-shadow: none;
}
#footer .form-block form textarea::placeholder {
  color: #fff;
}
@media (max-width: 1367px) {
  #footer .form-block form textarea {
    height: 120px;
  }
}
#footer .form-block form .sub-btn {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  background: #E9531D;
  border-radius: 8px;
  width: 100%;
  padding: 12px 15px;
}
#footer .copy-right {
  background: #2A2831;
}
#footer .copy-right p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  padding: 0.8rem 0;
}

.accordion-block {
  margin: 0 0 25px;
}
@media (max-width: 575px) {
  .accordion-block {
    padding: 0 10px;
  }
}
.accordion-block h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0 0 25px;
}
@media (max-width: 575px) {
  .accordion-block h3 {
    margin: 0 0 15px;
  }
}
.accordion-block .accordion-item {
  background: none;
  border: none;
}
.accordion-block .accordion-item .accordion-button {
  background: none;
  padding: 12px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #FFFFFF;
}
.accordion-block .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-block .accordion-item .accordion-button:after {
  background-repeat: no-repeat;
  background-size: 50%;
}
@media (max-width: 575px) {
  .accordion-block .accordion-item .accordion-button {
    font-size: 18px;
    line-height: 19px;
    padding: 10px 0;
    font-weight: 500;
  }
}
.accordion-block .accordion-item .accordion-body {
  padding: 0;
}
.accordion-block .accordion-item .accordion-body ul li {
  margin: 0 0 7px;
}
.accordion-block .accordion-item .accordion-body ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(90deg) !important;
}

@media (max-width: 992px) {
  .treat-area {
    padding: 0px;
  }
}
@media (max-width: 575px) {
  .treat-area {
    padding: 0px;
  }
}
.treat-area h2 {
  font-weight: 800;
  font-size: 42px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  margin: 0 0 35px;
  position: relative;
}
@media (max-width: 992px) {
  .treat-area h2 {
    font-size: 35px;
    line-height: 37px;
    margin: 0 0 25px;
  }
}
.treat-area h2:before {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: -30px;
  width: 200px;
  height: 5px;
  background: #e9531d;
  border-radius: 2px;
  content: "";
  margin: 0 auto;
}
@media (max-width: 992px) {
  .treat-area h2:before {
    width: 150px;
  }
}
.treat-area .long-bar {
  margin: 40px 0 0;
  background: #ffffff;
  border: 1px solid #e9531d;
  border-radius: 10px;
}
.treat-area .long-bar ul {
  display: flex;
}
.treat-area .long-bar ul .treatment-fees-tab {
  width: 100%;
}
.treat-area .long-bar ul li {
  width: 50%;
}
.treat-area .long-bar ul li:first-child .nav-link.active:before {
  left: 48px;
  right: auto;
  transform: rotate(180deg);
}
.treat-area .long-bar ul li:last-child button,
.treat-area .long-bar ul li:last-child h3 {
  border-radius: 0 10px 0 0;
}
.treat-area .long-bar ul li button,
.treat-area .long-bar ul li h3 {
  width: 100%;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdeee8;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #333333;
  position: relative;
  border-radius: 10px 0 0 0;
  z-index: 1;
  border-bottom: 1px solid #e9531d;
}
@media (max-width: 1200px) {
  .treat-area .long-bar ul li button,
  .treat-area .long-bar ul li h3 {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .treat-area .long-bar ul li button,
  .treat-area .long-bar ul li h3 {
    padding: 20px 30px;
  }
}
@media (max-width: 767px) {
  .treat-area .long-bar ul li button,
  .treat-area .long-bar ul li h3 {
    padding: 15px 15px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 575px) {
  .treat-area .long-bar ul li button,
  .treat-area .long-bar ul li h3 {
    font-size: 12px;
    padding: 15px 10px;
  }
  .treat-area .long-bar ul li button span,
  .treat-area .long-bar ul li h3 span {
    font-size: 14px;
  }
}
.treat-area .long-bar ul li .nav-link.active-treatment {
  border-radius: 10px 10px 0 0;
}
.treat-area .long-bar ul li .nav-link.active {
  border-radius: 10px 0 0 0;
  background: #e9531d;
  border: 1px solid #e9531d;
  color: #fff;
}
.treat-area .long-bar ul li .nav-link.active::before {
  position: absolute;
  top: auto;
  left: auto;
  right: 45px;
  bottom: -14px;
  content: "";
  background: url(../images/icon08.webp) no-repeat;
  width: 25px;
  height: 45px;
  background-size: 100%;
  z-index: -1;
}
@media (max-width: 767px) {
  .treat-area .long-bar ul li .nav-link.active::before {
    right: 29px;
    width: 15px;
    height: 30px;
  }
}
.treat-area .long-bar .sm-two {
  padding: 3rem 0;
}
@media (max-width: 992px) {
  .treat-area .long-bar .sm-two {
    padding: 2rem 1rem;
  }
}
@media (max-width: 767px) {
  .treat-area .long-bar .sm-two {
    padding: 1rem 1.5rem 0;
  }
}
@media (max-width: 575px) {
  .treat-area .long-bar .sm-two {
    padding: 1rem 1rem 0;
  }
}
.treat-area .long-bar .sm-two .block {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .treat-area .long-bar .sm-two .block {
    margin: 0 0 25px;
  }
}
@media (max-width: 575px) {
  .treat-area .long-bar .sm-two .block img {
    width: 25px;
  }
}
.treat-area .long-bar .sm-two .block h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #404048;
  padding: 0 0 0 1rem;
}
@media (max-width: 1367px) {
  .treat-area .long-bar .sm-two .block h3 {
    font-size: 17px;
    line-height: 21px;
  }
}
@media (max-width: 992px) {
  .treat-area .long-bar .sm-two .block h3 {
    padding: 0 0 0 1rem;
  }
}
@media (max-width: 767px) {
  .treat-area .long-bar .sm-two .block h3 {
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-weight: 600;
  }
}
@media (max-width: 767px) {
  .treat-area .long-bar .sm-two .block h3 {
    font-size: 12px;
    line-height: 14px;
  }
  .treat-area .long-bar .sm-two .block h3 img {
    width: 25px;
  }
}
.treat-area .long-bar .new {
  padding: 1.5rem 2rem 0;
}
@media (max-width: 767px) {
  .treat-area .long-bar .new {
    padding: 1rem 1.5rem 0;
  }
}
@media (max-width: 575px) {
  .treat-area .long-bar .new {
    padding: 1rem 1rem 0;
  }
}
.treat-area .long-bar .new .block {
  margin: 0 0 50px;
}
@media (max-width: 1200px) {
  .treat-area .long-bar .new .block {
    margin: 0 0 30px;
  }
}
.treat-area .long-bar .new .block h3 {
  padding: 0 0 0 1rem;
}

.after-left:after {
  left: 0;
  max-width: 200px;
}

.location-section .underline6 {
  display: inline-block;
  width: 140px;
  height: 4px;
  border-radius: 2px;
  background-color: #E9531D;
}
.location-section .location-text-1,
.location-section .location-text-2,
.location-section .location-text-3 {
  color: #404048;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px; /* is this correct/intended design? */
  text-align: left;
}
.location-section h2 {
  color: #E9531D;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
}
.location-section .location-text-4,
.location-section .location-text-5 {
  color: #404048;
  line-height: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: justify;
  margin-left: 5px;
}

@media screen and (max-width: 756px) {
  .location-section {
    padding-left: 30px;
    padding-right: 30px;
  }
  .location-section img {
    margin-top: 20px;
  }
}
.sm-two h4 {
  font-size: 17px;
  font-weight: 500;
  margin-left: 5px;
}

.search-controls .btn-filter {
  position: relative;
}
.search-controls .btn-filter .indicator {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.search-controls .select-all-filter,
.search-controls .clear-filter {
  width: 100%;
}
.search-controls .select-all-filter:hover,
.search-controls .clear-filter:hover {
  color: black;
}
.search-controls #filter-dropdown label:hover {
  cursor: pointer;
  color: black;
}

.detail-message {
  font-weight: 700;
  font-size: 1.2rem;
}

* {
  box-sizing: border-box;
}

.appointment-form {
  max-width: 1100px;
  margin: 0 auto;
}

.btn-search,
.btn-filter {
  align-self: end;
  width: 100%;
}

.btn-filter {
  float: right;
  margin-top: 0px;
  min-height: 40px;
  background: #fdeee8;
  border: 1px solid #bc6344;
  color: #e9531d;
  text-align: center;
  width: 100%;
}

.wheelchair label {
  align-self: center;
  padding-right: 5px;
}
.wheelchair .d-flex {
  height: 50px;
  text-align: center;
}

input[name=postcode] {
  text-transform: uppercase;
}

.submit-btn-input {
  border: 1px solid #e9531d;
  height: 38px;
  line-height: 33px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #fdeee8;
  color: #e9531d;
  font-weight: 700;
  width: 100%;
}

.submit-btn-input:hover {
  background-color: #e9531d;
  color: #ffffff;
}

.aWithD {
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 10px;
  border: 1px solid #e9531d;
}
.aWithD label {
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.aWithD .options {
  width: 100%;
  display: flex;
  font-size: 1rem;
  gap: 15px;
}

.aWithD .options .in {
  width: 30%;
}

.aWithD .options .in .input {
  margin-top: 5px;
}

.aWithD .options .distance {
  display: flex;
  flex-direction: column;
  position: relative;
}

.aWithD .options .distance select {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 19px;
  color: black;
  border: none;
  outline: none;
}

.input {
  padding: 10px;
}
.input input {
  width: 100%;
}

select.input {
  background-color: white;
}

.aWithD .options .in .input .aWithD .options .in .input select {
  width: 100%;
}

.aWithD .options .in .input {
  height: 50px;
  border-radius: 5px;
  border: 1px solid grey;
}

.aWithD .options .in .input input {
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  color: black;
}

.aWithD .options .in .input:focus-within {
  border-color: #e9531d;
}

.wheelchair .input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wheelchair .input {
  padding: 5px;
}

.wheelchair .yn {
  width: 50%;
  height: 100%;
}

.wheelchair .yn a {
  width: 100%;
  height: 100%;
  color: black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.wheelchair .btnYes a {
  color: white;
  background: black;
}

.searchBtn {
  width: 15%;
  height: 6vh;
  margin-top: auto;
  border-radius: 5px;
  background: #e9531d;
}

.searchBtn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.searchBtn .sIcon {
  color: white;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBtn .text {
  color: white;
}

.aWithD .checks {
  width: 100%;
  min-height: 12vh;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aWithD .checks label {
  font-size: 0.8rem;
}

.aWithD .checks input {
  accent-color: #e9531d;
}

.btn-search {
  width: 100%;
  height: 50px;
  margin-top: auto;
  border-radius: 5px;
  background: #e9531d;
}

.btn-search button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 5px;
}

.btn-search .sIcon {
  color: white;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 919px) {
  .btn-search {
    max-width: 120px;
  }
}
@media screen and (min-width: 1200px) {
  .btn-search.search,
  button[name=search] {
    max-width: 200px;
  }
}
@media screen and (max-width: 745px) {
  .wheelchair label {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 378px) {
  .aWithD p {
    font-size: 1.2 rem !important;
  }
}
.mbl-img-seach {
  display: none;
}

@media screen and (max-width: 918px) {
  .desk-img-seach {
    display: none;
  }
  .mbl-img-seach {
    display: block;
  }
  .search-bg {
    height: 60% !important;
  }
  .search-bg img {
    width: 100%;
    object-fit: cover;
  }
  .aWithD .options {
    flex-direction: column;
  }
  .aWithD .options .in {
    width: 100%;
  }
  .wheelchair label {
    font-size: 0.8rem;
  }
  .aWithD .options .in .input {
    width: 100%;
    height: 50px;
  }
  .btn-search {
    display: block;
    height: 50px;
  }
}
@media screen and (max-width: 692px) {
  .aWithD .checks {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
  }
}
@media screen and (max-width: 1003px) {
  .aWithD label {
    font-size: 14px;
  }
}
.search-section {
  background-image: url("/assets/images/search-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Search-heading p {
  margin-bottom: 10px;
  color: #333333;
  font-weight: 500;
}

.Search-cards {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.maintain-card {
  width: 81.5%;
  margin-left: auto;
  margin-right: auto;
}

.location,
.away,
.clock,
.time {
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.location span,
.away span,
.clock span,
.time span {
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 3px;
}
.location div,
.away div,
.clock div,
.time div {
  color: #1d1d1d;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.practice-section span {
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 3px;
}

.dental-record {
  color: #1d1d1d;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.booking-btn {
  margin-top: 25px;
  border: 1px solid #e9531d;
  height: 38px;
  line-height: 33px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #fdeee8;
  color: #e9531d;
  font-weight: 700;
}

.booking-btn:hover {
  background-color: #e9531d;
  color: #ffffff;
}

.check-list {
  margin-left: 100px;
}

.location-card {
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  border-radius: 7px;
}

@media screen and (max-width: 918px) {
  .location-card {
    max-width: 400px;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    border-radius: 7px;
    margin: 0 auto;
  }
}
.loader {
  animation: animName 2s linear infinite;
  transform-origin: center;
  margin: 20px;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rotate-circle-anim {
  display: flex;
  justify-content: center;
}

#btn-no {
  border-radius: 5px;
}

#loader-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional background overlay */
  z-index: 999999;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* Rectangle styling */
}
#loader-bg .loader-box {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 550px;
  width: 800px;
  max-width: 90%;
  max-height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#loader-bg svg {
  width: 40%;
  height: 40%;
  margin-bottom: 1rem;
}
#loader-bg .loader-text {
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
#loader-bg .loader {
  transform-origin: center;
  margin: 0 auto 2rem;
  border: 16px solid #f3f3f3;
  border-top-width: 16px;
  border-top-style: solid;
  border-top-color: rgb(243, 243, 243);
  border-top: 16px solid #e8541c;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  background-color: white;
}
#loader-bg .loader-confirm {
  margin-top: auto;
  position: absolute;
  bottom: 0px;
  right: 30px;
  border: 8px solid #f3f3f3 !important;
  border-top: 8px solid #e8541c !important;
  width: 40px !important;
  height: 40px !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#card-element {
  padding: 10px 5px;
  border: 1px solid #d3d3d3;
}

.navigate-back-container {
  color: #d3d3d3;
}
.navigate-back-container a {
  color: red;
  display: inline;
  font-size: 0.9rem;
  opacity: 0.5;
}
.navigate-back-container a:hover {
  opacity: 1;
  text-decoration: underline;
}

#stripePay:disabled {
  opacity: 0.3;
}

.update-color-orange {
  color: #e9531d !important;
}

.Payment-top {
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
}
.Payment-top span {
  color: #5c5b60;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
}
.Payment-top .logo-mid-pic {
  width: 120px;
}
.Payment-top .logo-mid-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.table-of-pay {
  border-top: 1px solid rgba(64, 64, 72, 0.2);
  border-bottom: 1px solid rgba(64, 64, 72, 0.2);
  height: 43px;
  line-height: 39px;
  background-color: #ffffff;
}
.table-of-pay a {
  display: block;
  width: 100%;
  text-align: center;
  color: #5c5b60;
}
.table-of-pay a:hover {
  color: #e9531d;
}
.table-of-pay .payment {
  border-right: 1px solid rgba(64, 64, 72, 0.2);
  border-left: 1px solid rgba(64, 64, 72, 0.2);
}
.table-of-pay span {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}

.justfortag,
.justfortag a {
  color: #e9531d;
}

.main-container {
  width: 100%;
  height: 100vh;
  background-image: url("/assets/images/wave.webp");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pay-form {
  margin-right: auto;
  margin-left: auto;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
}
.pay-form .pay-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pay-form .pay-top .top-left {
  flex-basis: 50%;
}
.pay-form .pay-top .top-left .pay-text p {
  color: #25242b;
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  margin-bottom: 4px;
}
.pay-form .pay-top .top-right .pay-price p {
  color: #e9531d;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
}
@media screen and (max-width: 400px) {
  .pay-form .pay-top .top-right .pay-price p {
    font-size: 28px;
  }
}
.pay-form .pay-top .top-right .pay-total p {
  color: #7b7b7b;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
}
.pay-form .debitform label {
  color: #7b7b7b;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}
.pay-form .debitform .debit-input {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  padding-left: 10px;
}
.pay-form .debitform .debit-input input {
  width: 90%;
  height: 100%;
}
.pay-form .debitform .debit-input img {
  cursor: pointer;
}
.pay-form .pay-btn {
  width: 100%;
  background-color: #e9531d;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  height: 40px;
  border-radius: 6px;
}
.pay-form .or-box {
  position: relative;
  margin-top: 10px;
}
.pay-form .or-box .line-box::before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #dcdcdc;
  position: absolute;
  top: 50%;
  left: 0;
}
.pay-form .or-box span {
  position: relative;
  background-color: #ffffff;
  width: 50px;
  display: inline-block;
  color: #b3b3b3;
}
.pay-form .payment-confirmation-icon {
  margin-right: 3px;
}
.pay-form .paypal-btn {
  width: 100%;
  background-color: #f0f3f8;
  height: 40px;
  border-radius: 6px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-form .cancelt-btn {
  margin-top: 25px;
  width: 100%;
  color: #f30000;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
@media screen and (max-width: 360px) {
  .pay-form .pay-logo-mobile {
    flex-wrap: wrap;
  }
  .pay-form .payment-confirmation-icon {
    margin-top: 3px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 525px) {
  .main-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .table-of-pay img {
    width: 11px;
  }
}
@media screen and (max-width: 354px) {
  .debit-input input {
    width: 100px;
    height: 100%;
  }
  .debit-input img {
    width: 7%;
    cursor: pointer;
  }
  .debit-input .Payment-top-mbl span {
    font-size: 9px !important;
  }
  .table-of-pay span {
    font-size: 7px;
  }
  .table-of-pay img {
    width: 8px;
  }
  .table-of-pay #confirm-text {
    display: none;
  }
}
@media screen and (min-width: 2000px) {
  .pay-form {
    top: 20%;
  }
}
@media screen and (min-width: 1500px) {
  .pay-form {
    top: 10%;
  }
}
@media screen and (max-width: 416px) {
  .table-of-pay span {
    font-size: 11px;
  }
  .table-of-pay img {
    width: 9px;
  }
}
.container .thanks_para {
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #404048;
  line-height: 25px;
}
.container .myaccount {
  color: black;
}
.container .thanks-heading p {
  color: #e9531d;
  font-style: normal;
  font-weight: 900;
  font-size: 41px;
  text-align: center;
}

.detail-section::after {
  content: url("/assets/images//mask.webp");
  position: absolute;
  bottom: 0;
  right: 0;
}

.detail-section {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 7px;
  margin-top: 40px;
  display: flex;
  position: relative;
}
.detail-section span {
  color: #1d1d1d;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.detail-section div {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.detail-section .section2 {
  width: 30%;
}
.detail-section .section1 {
  width: 30%;
}

@media screen and (max-width: 768px) {
  .detail-section {
    flex-direction: column;
  }
  .detail-section .section2 {
    width: 100%;
  }
  .detail-section .section1 {
    width: 100%;
  }
  .detail-section::after {
    content: url(/assets/images/mbl-sign.webp);
    position: absolute;
    top: 7%;
    right: 8%;
  }
}
label.terms {
  width: 100%;
  padding: 0 0 5px;
}
label.terms input {
  margin-right: 10px;
}

.adult-child {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.adult-child label {
  flex-basis: 47%;
  height: 45px;
  border-radius: 7px;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid #dcdcdc;
}
.adult-child label span:first-of-type {
  padding-top: 4px;
}
.adult-child label input {
  visibility: hidden;
}
.adult-child label img {
  visibility: hidden;
}
.adult-child label.active {
  border: 1px solid #e9531d;
  background-color: #fdeee8;
}
.adult-child label.active img {
  visibility: visible;
}

.form-box > div {
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #e0e0e0;
}
.form-box .form-label {
  color: #404048;
  margin: 0;
}
@media (max-width: 767px) {
  .form-box select[name=title] {
    max-width: 70px;
  }
  .form-box input[name=first_name],
  .form-box input[name=last_name],
  .form-box input[name=dob] {
    max-width: 250px;
  }
}
.form-box input[name=dob] {
  max-width: 200px;
}
.form-box select[name=title] {
  max-width: unset;
}
.form-box label.underline {
  text-decoration: underline #e9531d;
  text-underline-offset: 5px;
}

.primary-link {
  color: #e9531d;
  text-decoration: underline;
}

.proceedtopay-btn {
  width: 180px;
  height: 37px;
  background-color: #e9531d;
  border: #bc6344;
  border-radius: 7px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
}
.proceedtopay-btn:hover {
  background-color: #c04113;
}

.pay-form {
  max-width: 516px;
}

.detail-info-appointment {
  color: #5c5b60;
  background-color: #ffffff;
  border-radius: 7px;
  position: relative;
  border: 1px solid #e0e0e0;
  overflow: hidden;
}

.detail-info-appointment::after {
  content: url(/assets/images/shape-left.webp);
  position: absolute;
  left: 0;
  top: 5%;
}

.detail-info-appointment::before {
  content: url(/assets/images/shape-right.webp);
  position: absolute;
  right: 0;
  bottom: 5%;
}

.detail-info-card {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  color: #212529;
}
.detail-info-card img {
  margin-top: -3px;
}
.detail-info-card a {
  color: #212529;
  cursor: pointer;
}
.detail-info-card a:hover {
  color: #e9531d;
}

.dateform label {
  color: #7b7b7b;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}
.dateform .date-input {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  padding-left: 10px;
}
.dateform .date-input input {
  width: 90%;
  height: 100%;
}
.dateform .date-input img {
  cursor: pointer;
}

.behalf-of {
  color: #404048;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.termsandcond {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.termsandcond .check1term a {
  color: #e9531d;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-decoration: underline;
}
.termsandcond span {
  color: #4f4f4f;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.forget_password {
  text-align: right;
}
.forget_password a {
  color: #ff0000;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}

.login-btn-booking p {
  color: #4f4f4f;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
}
.login-btn-booking button {
  width: 100%;
  border-radius: 7px;
  height: 45px;
  text-align: center;
  background-color: #e9531d;
  color: #ffffff;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}

.Guardians-detail p {
  color: #25242b;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
}

.form-chil-detail p {
  color: #25242b;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
}

.hide-the-form {
  display: none;
}

@media screen and (max-width: 853px) {
  .appointment-booking-form {
    flex-direction: column;
  }
  .detail-info-appointment::after {
    content: "";
    position: absolute;
    left: 0;
    top: 5%;
  }
  .detail-info-appointment::before {
    content: url(/assets/images/mbl-shape-right.webp);
    position: absolute;
    right: 0;
    top: 0;
  }
}
@media screen and (max-width: 400px) {
  .adult-child span {
    font-size: calc(1vw + 1vh + 0.3vmin);
  }
  .adult-child img {
    width: 16px;
  }
}
.justfortag {
  position: relative;
}

.justfortag::before {
  position: absolute;
  content: "";
  width: 17px;
  height: 17px;
  background-color: white;
  transform: rotate(45deg);
  bottom: -11px;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

#booking-all-process input:focus {
  box-shadow: none;
}
#booking-all-process input:focus {
  border: 1px solid rgb(211, 210, 210);
}

#bookingthirdform,
#bookingtwoform,
#bookinglastform {
  display: none;
}

.error_message,
.error_message2,
.error_message3,
.error_message4 {
  margin-top: 10px;
  font-size: 16px;
  color: #e9531d;
  font-weight: 500px;
  font-style: normal;
}

.error_message5 {
  margin-top: 5px;
  font-size: 14px;
  color: #e9531d;
  font-weight: 300px;
  font-style: normal;
}

.dhx_qi_big_icon.icon_delete_all {
  color: #ff584c;
}

.dhx_qi_big_icon.icon_delete_all:hover {
  border: 1px solid rgba(255, 88, 76, 0.01);
  background-color: rgba(255, 88, 76, 0.3);
}

.video-block h2 {
  color: #323232;
  font-size: 2.5rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 50px;
  margin: 0 0 35px;
  position: relative;
  text-align: left;
  text-transform: capitalize;
}
.video-block h2::before {
  background: #e9531d;
  border-radius: 2px;
  bottom: -15px;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: auto;
  top: auto;
  width: 100px;
}
.video-block p {
  margin-bottom: 1rem;
}

.mo-box2 {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px 25px;
  height: 210px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .mo-box2 {
    height: 180px;
    padding: 15px;
  }
}
@media (max-width: 992px) {
  .mo-box2 {
    height: 160px;
    margin: 0 0 20px;
  }
}
.mo-box2:hover {
  background: #e9531d;
}
.mo-box2:hover p {
  color: #fff;
}
.mo-box2:hover h3 {
  color: #fff;
}
.mo-box2 h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  margin: 0 0 20px;
}
@media (max-width: 1200px) {
  .mo-box2 h3 {
    margin: 0 0 15px;
  }
}
@media (max-width: 992px) {
  .mo-box2 h3 {
    margin: 0 0 10px;
  }
}
@media (max-width: 575px) {
  .mo-box2 h3 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 370px) {
  .mo-box2 h3 {
    font-size: 14px;
    line-height: 16px;
  }
}
.mo-box2 p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #404048;
  margin: 0;
  text-align: left;
}
@media (max-width: 992px) {
  .mo-box2 p {
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .mo-box2 p {
    line-height: 22px;
  }
}
@media (max-width: 575px) {
  .mo-box2 p {
    line-height: 18px;
    font-size: 14px;
  }
}
@media (max-width: 370px) {
  .mo-box2 p {
    line-height: 16px;
    font-size: 13px;
  }
}

.mo-block2 {
  margin-top: 120px;
  margin-bottom: 130px;
  padding: 15rem 0 15rem;
  background: url(../images/banner03.webp) no-repeat center top;
  background-size: 100%;
  position: relative;
  z-index: 99;
}
@media (max-width: 1800px) {
  .mo-block2 {
    padding: 13rem 0 13rem;
  }
}
@media (max-width: 1700px) {
  .mo-block2 {
    padding: 12rem 0 12rem;
  }
}
@media (max-width: 1600px) {
  .mo-block2 {
    padding: 11rem 0 11rem;
  }
}
@media (max-width: 1500px) {
  .mo-block2 {
    padding: 10rem 0 10rem;
  }
}
@media (max-width: 1400px) {
  .mo-block2 {
    padding: 9rem 0 9rem;
  }
}
@media (max-width: 1367px) {
  .mo-block2 {
    padding: 7rem 0 7rem;
  }
}
@media (max-width: 1200px) {
  .mo-block2 {
    padding: 6rem 0 6rem;
  }
}
@media (max-width: 1092px) {
  .mo-block2 {
    padding: 4rem 0;
    background: #fdeee8;
  }
}
@media (max-width: 992px) {
  .mo-block2 {
    padding: 2rem 0;
    background: #fdeee8;
  }
}
@media (max-width: 575px) {
  .mo-block2 {
    padding: 2rem 5px;
  }
}
.mo-block2 h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 42px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #000000;
  margin: 0 0 30px;
  text-align: center;
}
@media (max-width: 992px) {
  .mo-block2 h2 {
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 20px;
  }
}
.mo-block2 p {
  font-weight: 500;
  font-size: 17px;
  color: #404048;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 992px) {
  .mo-block2 p {
    font-size: 20px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .mo-block2 p {
    font-size: 16px;
    line-height: 18px;
  }
}

.mo-box2 {
  margin-top: 45px;
  position: relative;
  width: 100%;
}
.mo-box2 p {
  text-align: start;
  color: #404048;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}
.mo-box2::before {
  content: url(/assets/images/for_about.webp);
  position: absolute;
  top: -10px;
  right: -10px;
}

.promise-heading {
  text-align: center;
}
.promise-heading h2 {
  margin-top: -50px;
}
.promise-heading .promo-line {
  display: inline-block;
  background-color: #e9531d;
  width: 120px;
  height: 3px;
  border-radius: 5px;
  transform: translateY(-25px);
}

@media screen and (max-width: 1381px) {
  .promise-heading h2 {
    margin-top: 0px;
  }
}
@media screen and (max-width: 991px) {
  .mo-box2 p {
    text-align: start;
    color: #404048;
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
  }
}
@media screen and (max-width: 751px) {
  .mo-box2 {
    height: 180px;
  }
  .mo-box2 p {
    text-align: start;
    color: #404048;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }
}
@media screen and (max-width: 435px) {
  .mo-box2 {
    height: 190px;
  }
  .mo-box2 p {
    text-align: start;
    color: #404048;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
  }
}
@media screen and (max-width: 355px) {
  .mo-box2 {
    height: 210px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .mo-box2 p {
    text-align: start;
    color: #404048;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}
.promo-text p {
  line-height: 25px;
}

.About-us-heading {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
}
.About-us-heading p {
  font-size: 40px;
  font-weight: 800;
  color: #e9531d;
  font-style: normal;
}
.About-us-heading .about-under-line {
  display: inline-block;
  width: 140px;
  height: 5px;
  background-color: #e9531d;
  border-radius: 50px;
}

@media screen and (max-width: 990px) {
  .about-under-line {
    display: none !important;
  }
  .its-all-started-h {
    margin-top: 20px !important;
  }
  .video-block .vid-text p {
    margin: 0 0 10px;
    line-height: 22px;
    margin: 25px;
  }
  .ordering {
    order: 1;
  }
}
.blog-card-section {
  margin-top: 30px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.blog-card {
  border-radius: 5px;
  box-shadow: 4px 4px 3px #eee;
  border: 1px solid #eee;
  margin: 10px;
  margin-bottom: 20px;
}

.blog-img {
  width: 100%;
  height: 210px;
}

.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.blog-heading {
  color: #111827;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
}
.blog-heading a {
  color: #212529;
}

.inner-blog-card {
  padding: 25px;
}

.blog-text {
  color: #6b7280;
  font-size: 13px;
}
.blog-text a {
  color: #212529;
}

.view-more-blog {
  color: #e9531d;
  font-size: 13px;
}

.blog-person {
  display: flex;
  align-items: center;
}

.blog-men-info {
  display: flex;
  flex-direction: column;
}

.blog-person-name {
  color: #111827;
  font-size: 14px;
}

.bloag-date {
  color: #6b7280;
  font-size: 12px;
}

@media screen and (max-width: 973px) {
  .blog-card-section {
    width: 90%;
    justify-content: space-between;
  }
  .blog-card {
    width: 47%;
    border-radius: 5px;
    box-shadow: 4px 4px 3px #eee;
    margin: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 755px) {
  .blog-card-section {
    width: 90%;
    justify-content: center;
  }
  .blog-card {
    width: 70%;
    border-radius: 5px;
    box-shadow: 4px 4px 3px #eee;
    margin: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 519px) {
  .blog-card {
    width: 100%;
    border-radius: 5px;
    box-shadow: 4px 4px 3px #eee;
    margin: 10px;
    margin-bottom: 20px;
  }
  .inner-blog-card {
    padding: 10px;
  }
}
.view-more-blog a {
  color: #e9531d;
}

.faqHeader {
  font-size: 27px;
  margin: 20px;
}

.panel-heading {
  position: relative;
  padding-right: 30px;
}

.FAQ-section {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e9531d;
  justify-content: space-around;
  border-radius: 8px;
  padding: 10px;
  min-height: 70vh;
  padding: 10px;
  padding-bottom: 35px;
  background-color: #ffffff;
  padding-bottom: 50px;
  padding-top: 30px;
}

.panel-title a:hover {
  text-decoration: none !important;
}

.panel-title a {
  text-decoration: none !important;
  color: #4d4d60;
}

.panel-heading {
  background-color: #ffffff !important;
  min-height: 60px;
}

.panel-title {
  line-height: 38px !important;
}

.panel-default {
  margin-top: 10px !important;
}

.faqs-img-box {
  width: 50%;
  height: 720px;
}

.faqimg {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

.Faq-heading {
  font-size: 40px;
  color: #e9531d;
  font-weight: 600;
}

.comon-under-line {
  text-align: center;
  margin-bottom: 30px;
}

.orange-line {
  display: inline-block;
  width: 110px;
  height: 4px;
  border-radius: 6px;
  background-color: #e9531d;
}

.orange-line2 {
  display: inline-block;
  width: 140px;
  height: 4px;
  border-radius: 6px;
  background-color: #e9531d;
}

.Faq-head {
  font-size: 25px;
  font-weight: 700;
  color: #e9531d;
}

.Faq-head span {
  display: block;
  margin-bottom: 20px;
}

@media screen and (max-width: 980px) {
  .faqs-img-box {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .question-section {
    max-width: 650px;
  }
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(180deg) !important;
}

.accordion-flush .accordion-item .accordion-button {
  border: 1px solid rgb(182, 182, 182);
  border-radius: 5px;
  min-height: 60px;
  line-height: 25px;
}

.accordion-button:not(.collapsed) {
  color: #4d4d60;
  background-color: #ffffff;
}

.accordion-item {
  border: none;
}

/* /* .panel-heading [data-toggle="collapse"].collapsed:after { s */
.getin-touct {
  width: 100%;
}
.getin-touct .inner-get-data {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.getin-touct .get-in-heading {
  width: 100%;
}
.getin-touct .get-in-form {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #01021d;
}
.getin-touct .get-in-form ::placeholder {
  color: #01021d;
  font-size: 14px;
  font-weight: 600;
}
.getin-touct .get-in-form input,
.getin-touct .get-in-form select {
  margin-bottom: 20px;
  height: 50px;
  color: #01021d;
  color: #01021d;
  font-size: 14px;
  font-weight: 600;
}
.getin-touct .get-in-form .Send-Form {
  height: 50px;
  width: 100%;
  background-color: #e9531d;
  border-radius: 7px;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
}

.touch-sentance {
  margin-top: 20px;
  color: #4d4d60;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
}

.get-in-form2 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #01021d;
}
.get-in-form2 input,
.get-in-form2 select {
  margin-bottom: 10px;
  height: 50px;
  color: #01021d;
}
.get-in-form2 .reason-input {
  margin-top: -15px;
}
.get-in-form2 .Send-Form {
  height: 50px;
  width: 100%;
  background-color: #e9531d;
  border-radius: 7px;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
}
.get-in-form2 .custom-number-input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  display: flex;
  border: 1px solid #dee2e6;
  align-items: flex-start;
  padding-left: 5px;
}
.get-in-form2 .custom-number-input input {
  height: 90%;
}
.get-in-form2 .custom-number-input select {
  height: 90%;
  color: #6b7280;
}
.get-in-form2 .custom-number-input input {
  border: none;
}
.get-in-form2 .custom-number-input select {
  border: none;
}

.get-in-form label {
  margin-bottom: 1px;
  color: #404048;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

@media screen and (max-width: 944px) {
  .inner-get-data {
    width: 70% !important;
  }
}
#mobile-get {
  display: none;
}

@media screen and (max-width: 750px) {
  #desktop-get {
    display: none;
  }
  #mobile-get {
    display: block;
  }
}
.blogarea {
  width: 90%;
  border-radius: 5px;
  border: 1px solid #e9531d;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
}
.blogarea p {
  color: #4d4d60;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}

.top-blog-head {
  text-align: center;
}
.top-blog-head p {
  font-size: 30px;
  font-weight: 700;
  color: #e9531d;
  margin-bottom: 30px;
  line-height: 35px;
}

.blog-s-date {
  color: #4d4d60;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 15px;
  line-height: 20px;
}

.cavity-question p {
  font-size: 28px;
  color: #e9531d;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 20px;
}

.cavity-ans {
  font-size: 16px;
  color: #4d4d60;
  margin-top: 10px;
  line-height: 20px;
}

.symtoms-question p {
  color: #e9531d;
  font-size: 28px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.symtoms-ans {
  font-size: 16px;
  color: #4d4d60;
  font-style: normal;
  line-height: 25px;
}

.symtoms-ans p {
  font-size: 16px;
  color: #4d4d60;
  font-style: normal;
  line-height: 25px;
  margin-bottom: 10px;
}

.symtoms-ans ul li {
  list-style: disc;
  margin-left: 25px;
}

.cause-question p {
  color: #e9531d;
  font-size: 28px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.plaqu-info {
  color: #e9531d;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.acid-attack-head {
  color: #e9531d;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.pulp-head {
  color: #e9531d;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.risk-factor span {
  display: inline-block;
  color: #e9531d;
  font-size: 28px;
  font-weight: 600;
  line-height: 20px;
}

.location-teeth {
  color: #e9531d;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.brushing {
  color: #e9531d;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.snacking {
  color: #e9531d;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dry-mouth {
  color: #e9531d;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.floride {
  color: #e9531d;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.copli-head span {
  display: inline-block;
  color: #e9531d;
  font-size: 28px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.small-orange-color {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: #e9531d;
  border-radius: 50%;
  margin-right: 10px;
}

.serve-box {
  display: flex;
  font-size: 18px;
  align-items: center;
  color: #e9531d;
  font-weight: 600;
  margin-top: 15px;
}

.serve-box2 {
  display: flex;
  font-size: 18px;
  color: #e9531d;
  font-weight: 600;
  margin-top: 15px;
}

.prevention p {
  color: #e9531d;
  font-size: 28px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.simple-text {
  font-size: 16px;
  color: #4d4d60;
  margin-top: 10px;
  line-height: 20px;
}

.orange-line-down {
  display: inline-block;
  width: 120px;
  height: 5px;
  background-color: #e9531d;
  border-radius: 50px;
}

.orange-line-down2 {
  display: inline-block;
  width: 100px;
  height: 3px;
  background-color: #e9531d;
  border-radius: 50px;
}

.orange-line-down3 {
  display: inline-block;
  width: 140px;
  height: 3px;
  background-color: #e9531d;
  border-radius: 50px;
}

.plaqu-info {
  display: flex;
  align-items: center;
}

.blog-heading-top {
  margin-bottom: 40px;
}

.top-blog-head2 {
  text-align: center;
}
.top-blog-head2 p {
  font-size: 40px;
  font-weight: 800;
  color: #e9531d;
  font-style: normal;
}

@media screen and (max-width: 900px) {
  .top-blog-head {
    text-align: start;
    line-height: 70px;
  }
  .top-blog-head p {
    line-height: 1;
  }
  .for-hide-line {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .blogarea p {
    font-size: 12px;
  }
  .symtoms-ans {
    font-size: 12px;
  }
  .simple-text {
    font-size: 12px;
  }
  .top-blog-head p {
    line-height: 1;
  }
  .cavity-question p {
    margin-bottom: 0;
    font-size: 15px;
  }
  .orange-line-down2 {
    width: 60px;
    height: 3px;
    margin-top: 7px;
  }
  .symtoms-question p {
    margin-bottom: 0;
    font-size: 15px;
  }
  .cause-question p {
    margin-bottom: 0;
    font-size: 15px;
  }
  .small-orange-color {
    width: 5px;
    height: 5px;
  }
  .plaqu-info {
    margin-bottom: 8px;
    font-size: 13px;
  }
  .top-blog-head p {
    font-size: 17px;
    line-height: 25px;
  }
  .risk-factor > p {
    margin-top: 0px;
  }
  .copli-head span {
    margin-bottom: 0;
    font-size: 15px;
  }
  .serve-box {
    font-size: 12px;
  }
  .serve-box span {
    margin-left: 10px;
  }
  .serve-box2 {
    font-size: 12px;
  }
  .prevention p {
    margin-bottom: 0;
    font-size: 15px;
  }
}
.dentist-image-box {
  display: flex;
  align-items: center;
}

.fees-heading {
  text-align: center;
}
.fees-heading span {
  margin-top: 15px;
  display: inline-block;
  color: #6b7280;
  font-size: 15px;
  line-height: 24px;
}

.mbl-fees-card {
  border: 1px solid rgb(189, 189, 189);
  box-shadow: 0 0 2 px #eee;
  padding: 20px;
  width: 90%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
}
.mbl-fees-card .mbl-appoi-head {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: black;
}
.mbl-fees-card .mbl-appoi-head span {
  color: #e9531d;
}
.mbl-fees-card .mbl-vc-img {
  width: 100%;
  height: 320px;
  border-radius: 10px;
}
.mbl-fees-card .mbl-vc-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.price-fee-head {
  color: #111827;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 20px;
}
.price-fee-head .price-colored {
  color: #e9531d;
  display: inline-block;
  font-size: 30px !important;
  font-weight: 900;
}

.mbl-fee-text {
  margin-top: 15px;
  color: #404048;
  font-size: 16px;
  line-height: 30px;
}

.mbl-fee-text2 {
  margin-top: 15px;
  color: #404048;
  font-size: 16px;
  line-height: 30px;
}
.mbl-fee-text2 .price-colored {
  color: #e9531d !important;
  font-weight: 500;
}

.mbl-fee-btn {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  background-color: rgba(233, 83, 29, 0.2);
  color: #e9531d;
  height: 50px;
  border-radius: 7px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.fees-statement {
  margin-top: -60px;
}

@media screen and (max-width: 991px) {
  #desktop-fees {
    display: none;
  }
  #mobile-fees {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .fees-statement {
    margin-top: 20px;
  }
}
@media screen and (max-width: 550px) {
  .mbl-vc-img {
    height: 270px !important;
  }
}
.reviewe {
  margin-bottom: 25px;
  margin-top: 50px;
}

.reviewBox {
  width: 90%;
  height: 100vh;
  padding: 30px 0 10px 50px;
  overflow: hidden;
}

.headerss {
  display: flex;
  align-items: center;
  gap: 30%;
}

#rev {
  color: orange;
  margin-bottom: 15px;
}

#heading {
  font-weight: 600;
  font-size: 35px;
}

.arrows {
  display: flex;
  gap: 10px;
}

.prevArrow {
  background: orange;
}

.nextArrow {
  background: black;
}

.prevArrow,
.nextArrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prevArrow img,
.nextArrow img {
  width: 40%;
}

/*start of main slider*/
.slider {
  position: relative;
  width: 2000px;
  height: 260px;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  gap: 10px;
  transform: translateX(0px);
  transition: all 0.5s ease-in-out;
}

.card {
  width: 400px;
  height: 100%;
  padding: 40px 40px 0px 40px;
  box-shadow: 5px 5px 10px -6px rgba(0, 0, 0, 0.1), -5px 5px 10px -6px rgba(0, 0, 0, 0.1), 0px -1px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
}

#reviews .card {
  pointer-events: none;
}

.cardText {
  font-size: 15px;
  color: #3e3e3e;
  line-height: 35px !important;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Lato";
  font-style: normal;
}

.cardTop {
  display: flex;
  justify-content: space-between;
}

.leftS {
  display: flex;
  align-items: center;
}

.profile {
  width: 50px;
  margin-right: 10px;
}

#userName {
  font-size: 15px;
  font-weight: 600;
  margin-top: -8px;
  margin-bottom: 8px;
}

#datee {
  font-size: 10px;
  color: grey;
}

.profile img {
  width: 100%;
  object-fit: cover;
}

.glide__slide {
  height: 400px;
}

.card {
  width: 100%;
}

.glide__arrow {
  right: 10%;
  top: -20%;
  background-color: black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.glide__arrow--prev {
  transform: translate(-110%, -120%);
}

.glide__arrow--next {
  transform: translate(0, -120%);
}

.reviewBox {
  width: 90%;
  height: 100vh;
  padding: 30px 0 10px 50px;
  overflow: hidden;
}

.headerss {
  display: flex;
  align-items: center;
  gap: 30%;
}

#rev {
  color: orange;
  margin-bottom: 15px;
}

#heading {
  font-weight: 600;
  font-size: 35px;
}

.arrows {
  display: flex;
  gap: 10px;
}

.nextArrow {
  background: black;
}

.prevArrow,
.nextArrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prevArrow img,
.nextArrow img {
  width: 40%;
}

/*start of main slider*/
.slider {
  position: relative;
  width: 2000px;
  height: 260px;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  gap: 10px;
  transform: translateX(0px);
  transition: all 0.5s ease-in-out;
}

.cardText {
  margin-top: 20px;
}

.cardText p {
  font-size: 14px;
  line-height: 20px;
}

.stars {
  margin-top: 25px;
}

.glide__arrow--prev {
  background-color: #e9531d !important;
}

.rewi-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4% !important;
}

.review-head {
  display: inline-block;
  color: #e9531d;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 10px;
}

.rewi-heading .line-reviwe {
  display: inline-block;
  width: 160px;
  height: 5px;
  background-color: #e9531d;
  border-radius: 5px;
}

.what-user-saw {
  padding-left: 100px;
}

.rev-start {
  color: #e9531d;
  font-size: 17px;
  font-weight: 600;
}

.revi-start-heahing {
  color: black;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 10px;
}

.reviewBox {
  width: 90%;
  height: 100vh;
  padding: 30px 0 10px 50px;
  overflow: hidden;
}

.headerss {
  display: flex;
  align-items: center;
  gap: 30%;
}

#rev {
  color: orange;
  margin-bottom: 15px;
}

#heading {
  font-weight: 600;
  font-size: 35px;
}

.arrows {
  display: flex;
  gap: 10px;
}

.prevArrow {
  background: orange;
}

.nextArrow {
  background: black;
}

.prevArrow,
.nextArrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prevArrow img,
.nextArrow img {
  width: 40%;
}

/*start of main slider*/
.slider {
  position: relative;
  width: 2000px;
  height: 260px;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  gap: 10px;
  transform: translateX(0px);
  transition: all 0.5s ease-in-out;
}

.rightComma img {
  width: 100%;
  object-fit: cover;
}

#mobile-review {
  display: none;
}

.mbl-revi-line {
  display: none;
  width: 80%;
  height: 1px;
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1193px) {
  .dental-problem {
    margin-top: 200px;
  }
  #desktop-rewiew {
    display: none;
  }
  #mobile-review {
    display: block;
  }
  .reviewBox {
    border: none;
    width: 100%;
    overflow: visible;
  }
  .slider {
    width: 80%;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
  .what-user-saw {
    display: none;
  }
  .line-reviwe {
    display: none !important;
  }
  .mbl-revi-line {
    display: block;
    margin-bottom: -25px;
  }
  .arrows {
    display: none;
  }
  .card {
    position: relative;
    min-height: 200px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 976px) {
  .cardText p {
    font-size: 11px;
  }
}
@media screen and (max-width: 950px) {
  .reviewBox {
    padding-right: 40px;
  }
  .dental-problem {
    margin-top: 80%;
  }
  #rev {
    font-weight: 600;
    text-align: center;
    font-size: 50px;
  }
  #heading {
    display: none;
  }
  .card {
    padding-right: 0px;
    width: 100%;
  }
  .rightComma {
    display: none;
  }
}
@media screen and (max-width: 792px) {
  .dental-problem {
    margin-top: 120%;
  }
}
@media screen and (max-width: 525px) {
  .cardText p {
    font-size: 13px;
  }
}
@media screen and (max-width: 465px) {
  .cardText p {
    font-size: 11px;
  }
}
@media screen and (max-width: 550px) {
  .reviewBox {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .dental-problem {
    margin-top: 170%;
  }
  .slider {
    width: 100%;
    min-height: 44%;
  }
  .cardText p {
    font-size: 11px;
  }
}
@media screen and (max-width: 430px) {
  .dental-problem {
    margin-top: 190%;
  }
}
@media screen and (max-width: 382px) {
  .dental-problem {
    margin-top: 210%;
  }
}
@media screen and (max-width: 344px) {
  .dental-problem {
    margin-top: 240%;
  }
}
.Add-child-section .child-heading-t {
  font-size: 25px;
  font-weight: 700;
  color: #e9531d;
}
.Add-child-section .child-detail-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Add-child-section .child-fields {
  width: 100%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields::before {
  position: absolute;
  content: "First Name";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields2 {
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields2::before {
  position: absolute;
  content: "Last Name";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields-title::after {
  left: 21px;
  top: -10px;
  position: absolute;
  content: "Title";
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
  z-index: 2;
}
.Add-child-section .child-fields-title select {
  width: 100px;
}
.Add-child-section .child-fields2 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-dob p {
  font-size: 25px;
  font-weight: 700;
  color: #e9531d;
}
.Add-child-section .dob-fields {
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  padding-right: 30px;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
  display: flex;
  justify-content: space-between;
}
.Add-child-section .dob-fields span svg {
  width: 25px;
  height: 25px;
  margin-left: auto;
}
.Add-child-section .dob-fields input {
  color: #000000;
  font-weight: 700;
  width: 80%;
  background-color: transparent;
}

.child-register-btn p {
  width: 550px;
  height: 60px;
  background-color: #e9531d;
  box-shadow: 0px 0px 4px #615f5f;
  line-height: 60px;
  color: #ffffff;
  margin-left: auto;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
}

@media screen and (max-width: 794px) {
  .child-register-btn p {
    width: 100%;
  }
  .child-detail-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: unset;
  }
  .child-fields {
    width: 100% !important;
  }
  .dob-fields {
    width: 100% !important;
  }
}
@media screen and (max-width: 629px) {
  .Add-child-section {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 409px) {
  .Add-child-section {
    margin-left: auto;
    margin-right: auto;
  }
}
.appoint-box {
  cursor: pointer;
}
.appoint-box .nav-active-line {
  bottom: -2px;
}
.appoint-box.active .appoint-texts {
  color: #e9531d !important;
}

.appointments-section {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.appointment-heading {
  text-align: center;
}

.appointment-head {
  font-size: 45px;
  color: #e9531d;
  font-weight: 600;
}

.appointment-line {
  display: inline-block;
  width: 270px;
  height: 3px;
  background-color: #e9531d;
  transform: translateY(-10px);
}

.inner-appointment {
  width: 100%;
}
.inner-appointment .appointment-nav {
  width: 100%;
  border-bottom: 2px solid #dadada;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.inner-appointment .appointment-nav .appoint-box {
  flex-basis: 14%;
}
.inner-appointment .appointment-nav .appoint-box span svg {
  width: 20px;
}
.inner-appointment .appointment-nav .appoint-texts2 {
  flex-basis: 20% !important;
}
.inner-appointment .appointment-nav .appoint-texts {
  color: #676767;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}
.inner-appointment .appoints-detail-card {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #dadada;
}
.inner-appointment .appoints-detail-card .appoint-person-name {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  font-size: 23px;
  font-weight: 500;
  color: #ffffff;
  background-color: #e9531d;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-card {
  background-color: #fff7f4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box {
  display: flex;
  justify-content: space-around;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box .patient-info-box {
  flex-basis: 25%;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box .patient-info-box .pateint-info-bold {
  color: #000000;
  font-size: 15px;
  font-weight: 800;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box .patient-info-box .pateint-info-light {
  color: #676767;
  font-size: 13px;
  margin-top: 4px;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box .patient-info-box .patient-detail-box {
  margin-bottom: 15px;
}
.inner-appointment .leave-review-btn {
  padding-bottom: 20px;
}
.inner-appointment .leave-review-btn p {
  width: 250px;
  height: 40px;
  background-color: #e9531d;
  box-shadow: 0px 0px 2px #b8b5b5;
  line-height: 40px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.inner-appointment {
  width: 100%;
}
.inner-appointment .appointment-nav {
  width: 100%;
  border-bottom: 2px solid #dadada;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.inner-appointment .appointment-nav .appoint-box {
  flex-basis: 14%;
}
.inner-appointment .appointment-nav .appoint-box span svg {
  width: 20px;
}
.inner-appointment .appointment-nav .appoint-texts2 {
  flex-basis: 20% !important;
}
.inner-appointment .appointment-nav .appoint-texts {
  color: #676767;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}
.inner-appointment .appoints-detail-card {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #dadada;
}
.inner-appointment .appoints-detail-card .appoint-person-name {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  font-size: 23px;
  font-weight: 500;
  color: #ffffff;
  background-color: #e9531d;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-card {
  background-color: #fff7f4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box-mbl {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 50px;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-bold {
  color: #000000;
  font-size: 15px;
  font-weight: 800;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-light {
  color: #676767;
  font-size: 13px;
  margin-top: 4px;
}
.inner-appointment .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .patient-detail-box {
  flex-basis: 45%;
  margin-bottom: 15px;
}
.inner-appointment .leave-review-btn {
  padding-bottom: 20px;
}
.inner-appointment .leave-review-btn p {
  width: 250px;
  height: 40px;
  background-color: #e9531d;
  box-shadow: 0px 0px 2px #b8b5b5;
  line-height: 40px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 1120px) {
  .appoint-box {
    flex-basis: 20% !important;
  }
  .appoint-box .appoint-texts {
    font-size: 11px !important;
  }
  .appoint-box span svg {
    width: 16px !important;
    height: 16px !important;
  }
}
.Mobile-version-appointment {
  display: none;
}

@media screen and (max-width: 1000px) {
  .inner-appointment .appoints-detail-card .appoint-inner-detail-box .patient-info-box {
    flex-basis: 30%;
  }
  .inner-appointment .appoints-detail-card .appoint-inner-detail-box {
    padding-left: 20px;
  }
}
@media screen and (max-width: 890px) {
  .inner-appointment .appoints-detail-card .appoint-inner-detail-box .patient-info-box .pateint-info-light {
    font-size: 11px;
  }
}
.add-kid {
  display: none;
}

@media screen and (max-width: 794px) {
  .inner-appointment .appoints-detail-card .appoint-person-name {
    font-size: 18px !important;
  }
  .Desktop-version-appointment {
    display: none !important;
  }
  .Mobile-version-appointment {
    display: block;
  }
  .appoint-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 15px;
  }
  .add-child {
    display: none !important;
  }
  .add-kid {
    display: block !important;
  }
  .change-pass {
    margin-top: 6px !important;
    line-height: 10px;
  }
  .change-pass .change-pass-text {
    margin-top: 2px !important;
  }
  .appointment-head {
    font-size: 30px;
  }
  .appointment-line {
    width: 80%;
    background-color: #dadada;
    height: 1px;
    transform: translateX(0px);
  }
}
@media screen and (max-width: 629px) {
  .appointments-section {
    width: 100%;
  }
}
@media screen and (max-width: 525px) {
  .inner-appointment .appoints-detail-card {
    width: 90%;
  }
  .inner-appointment .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-light {
    font-size: 11px;
  }
}
@media screen and (max-width: 370px) {
  .inner-appointment .appoints-detail-card {
    width: 95%;
  }
  .inner-appointment .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-light {
    font-size: 10px;
  }
}
.appoint-box {
  position: relative;
}

.appoint-box span svg {
  color: #e9531d !important;
}

.nav-active-line {
  display: inline-block;
  width: 130px;
  position: absolute;
  bottom: -12px;
  height: 2px;
  background-color: #e9531d;
  left: 0;
}

.box-color-orange {
  color: #e9531d !important;
}

#Add_child_area,
#change_password_area,
#view_details_area,
#edit_details_area {
  display: none;
}

@media screen and (max-width: 700px) {
  .nav-active-line {
    width: 100% !important;
  }
}
.special {
  flex-basis: 11% !important;
}

@media screen and (max-width: 1200px) {
  .special {
    flex-basis: 15% !important;
  }
}
@media screen and (max-width: 1000px) {
  .special {
    flex-basis: 20% !important;
  }
}
@media screen and (max-width: 400px) {
  .thankyou_emerdency .inner-thanks {
    font-size: 12px;
  }
}
@media screen and (max-width: 360px) {
  .appointment-nav {
    font-size: 10px !important;
  }
  .appointment-nav .appoint-texts {
    font-size: 10px !important;
    line-height: 10px !important;
  }
}
.apointment_event {
  padding-left: 15px;
}

.view-detail-card {
  border-radius: 15px;
  background-color: #fff7f4;
  box-shadow: 0px 0px 5px #DADADA;
  padding: 30px 30px 0px 30px;
}

@media screen and (max-width: 1120px) {
  .inner-appointment .appointment-nav .appoint-box {
    padding-left: 0px;
  }
  .inner-appointment .appointment-nav .appoint-texts {
    margin-top: 5px !important;
    line-height: 13px !important;
  }
  .change-pass {
    margin-top: 0px !important;
  }
}
.rating-section {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.rating-section .rateing-heading {
  width: 100%;
  text-align: center;
}
.rating-section .rateing-heading .rating-head {
  font-size: 45px;
  font-weight: 600;
  color: #e9531d;
}
.rating-section .rateing-heading .rating-line {
  display: inline-block;
  width: 120px;
  height: 3px;
  background-color: #e9531d;
}
.rating-section .appreciation {
  margin-top: 30px;
  color: #000000;
  font-size: 32px;
  font-weight: 600;
}
.rating-section .about-dentist {
  margin-top: 25px;
  font-size: 25px;
  font-weight: 600;
  color: #e9531d;
}
.rating-section .Rate-dentist1 {
  color: #000000;
  font-size: 17px;
  font-weight: 700;
  margin-top: 20px;
}
.rating-section .share-expo {
  color: #676767;
  font-size: 15px;
  margin-top: 7px;
}
.rating-section .rate-me {
  margin-top: 25px;
  width: 100%;
  display: flex;
}
.rating-section .ratin-box {
  width: 60px;
  height: 35px;
  text-align: center;
  line-height: 32px;
  margin-right: 20px;
  border-radius: 3px;
  font-weight: 600;
  border: 1px solid #e9531d;
  color: #e9531d;
  cursor: pointer;
}
.rating-section .ratin-box2 {
  width: 60px;
  height: 35px;
  text-align: center;
  line-height: 32px;
  margin-right: 20px;
  border-radius: 3px;
  font-weight: 600;
  border: 1px solid #e9531d;
  color: #e9531d;
  cursor: pointer;
}
.rating-section .orange-color {
  background-color: #e9531d;
  color: #ffffff;
}
.rating-section .Comments {
  margin-top: 25px;
  color: #000000;
  font-size: 17px;
  font-weight: 700;
}
.rating-section .comment-area {
  width: 100%;
  border: 1px solid #e9531d;
  height: 250px;
  border-radius: 7px;
  margin-top: 5px;
  padding: 20px;
}
.rating-section .comment-area textarea {
  outline: none;
  border: none;
  color: #676767;
  width: 100%;
  height: 100%;
  background: transparent;
  resize: none;
}

.rating-btn p {
  cursor: pointer;
  margin-top: 30px;
  width: 350px;
  height: 50px;
  line-height: 50px;
  background-color: #e9531d;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
  margin-left: auto;
  box-shadow: 3px 3px 3px #acabab;
}

@media screen and (max-width: 568px) {
  .appreciation {
    font-size: 25px !important;
  }
  .rating-section .about-dentist {
    font-size: 20px !important;
  }
  .rating-btn p {
    width: 100% !important;
  }
  .rating-section .rateing-heading .rating-head {
    font-size: 30px !important;
  }
  .rating-section .rateing-heading .rating-line {
    width: 100% !important;
    background-color: #dadada !important;
    height: 1px !important;
  }
}
@media screen and (max-width: 355px) {
  .appreciation {
    font-size: 21px !important;
  }
  .rating-section .about-dentist {
    font-size: 17px !important;
  }
  .rating-section .ratin-box {
    width: 50px;
    height: 30px;
    line-height: 30px;
    font-weight: 500;
    font-size: 12px;
  }
}
.Add-new-section .new-heading-t {
  font-size: 25px;
  font-weight: 700;
  color: #e9531d;
}
.Add-new-section .new-detail-inner {
  width: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}
.Add-new-section .new-fields {
  width: 100%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
  display: flex;
  justify-content: space-between;
}
.Add-new-section .new-fields span {
  cursor: pointer;
}
.Add-new-section .new-fields span svg {
  width: 30px;
  height: 30px;
  margin-left: auto;
}
.Add-new-section .new-fields::before {
  position: absolute;
  content: "Enter New Password";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-new-section .new-fields-existing::before {
  content: "Current Password";
}
.Add-new-section .new-fields input {
  color: #000000;
  font-weight: 700;
  width: 80%;
  background-color: transparent;
}
.Add-new-section .new-fields2 {
  width: 100%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
  display: flex;
  justify-content: space-between;
}
.Add-new-section .new-fields2 span {
  cursor: pointer;
}
.Add-new-section .new-fields2 span svg {
  width: 30px;
  height: 30px;
  margin-left: auto;
}
.Add-new-section .new-fields2::before {
  position: absolute;
  content: "Re-Enter New Password";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-new-section .new-fields2 input {
  color: #000000;
  font-weight: 700;
  width: 80%;
  background-color: transparent;
}

.old-pass p {
  font-size: 25px;
  font-weight: 700;
  color: #e9531d;
}

.old-fields {
  margin-top: 20px;
  width: 48%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  padding-right: 30px;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
  display: flex;
  justify-content: space-between;
}
.old-fields span svg {
  width: 25px;
  height: 25px;
  margin-left: auto;
}

.old-fields::before {
  position: absolute;
  content: "Enter Old Password";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}

.old-fields input {
  color: #000000;
  font-weight: 700;
  width: 80%;
  background-color: transparent;
}

.new-register-btn p {
  margin: 0 auto;
  width: 550px;
  height: 60px;
  background-color: #e9531d;
  box-shadow: 0px 0px 4px #615f5f;
  line-height: 60px;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
}

@media screen and (max-width: 794px) {
  .new-fields2 span {
    display: none !important;
  }
  .new-fields span {
    display: none !important;
  }
  .new-register-btn p {
    width: 100%;
  }
  .new-detail-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    justify-content: unset;
  }
  .new-fields {
    width: 100% !important;
  }
  .old-fields {
    width: 100% !important;
  }
}
#appointment-form {
  padding: 0;
}

.Add-child-section .dob-fields {
  width: 200px !important;
}
.Add-child-section .dob-fields input {
  color: grey !important;
}

.disabled-custom {
  cursor: not-allowed !important;
}
.disabled-custom.child-fields1, .disabled-custom.child-fields3,
.disabled-custom .dob-fields {
  border: 1px solid grey !important;
  color: grey !important;
  opacity: 0.5;
}
.disabled-custom input {
  cursor: not-allowed !important;
}

.edit-detail-field .child-detail-inner {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.Add-child-section .child-heading-t {
  font-size: 25px;
  font-weight: 700;
  color: #e9531d;
}
.Add-child-section .child-fields1 {
  width: 100%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields1 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields3 {
  width: 100%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields3 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields4 {
  width: 49%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields4::before {
  position: absolute;
  content: "House Number or Name";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields4 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields5 {
  width: 49%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields5::before {
  position: absolute;
  content: "Street or Road";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields5 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields6 {
  width: 49%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields6::before {
  position: absolute;
  content: "Town or City";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields6 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields7 {
  width: 49%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields7::before {
  position: absolute;
  content: "Post Code";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields-line2::before {
  position: absolute;
  content: "Address Line 2";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields7 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields8 {
  width: 49%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields8::before {
  position: absolute;
  content: "Contact Number";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields8 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .child-fields9 {
  width: 49%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .child-fields9::before {
  position: absolute;
  content: "Email Address";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}
.Add-child-section .child-fields9 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}
.Add-child-section .Address-field {
  width: 100%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}
.Add-child-section .Address-field input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  height: 100%;
}
.Add-child-section .child-dob p {
  font-size: 25px;
  font-weight: 700;
  color: #e9531d;
}
.Add-child-section .dob-fields {
  width: 60%;
  height: 55px;
  border: 1px solid #e9531d;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  padding-right: 30px;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
  display: flex;
  justify-content: space-between;
}
.Add-child-section .dob-fields span svg {
  width: 25px;
  height: 25px;
  margin-left: auto;
}
.Add-child-section .dob-fields input {
  color: #000000;
  font-weight: 700;
  width: 80%;
  background-color: transparent;
}

.child-register-btn {
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 300px;
  height: 60px;
  background-color: #e9531d;
  box-shadow: 0px 0px 4px #615f5f;
  line-height: 60px;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
  color: white !important;
}

.Add-child-section .Address-field textarea {
  display: none;
}

@media screen and (max-width: 794px) {
  .Add-child-section .Address-field input {
    display: none;
  }
  .Add-child-section .Address-field textarea {
    display: block;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
  }
  .child-register-btn p {
    width: 100%;
  }
  .edit-detail-field .child-heading-t {
    margin-bottom: 25px;
  }
  .edit-detail-field .child-detail-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    justify-content: unset;
  }
  .child-fields,
  .child-fields1,
  .child-fields3 {
    width: 100% !important;
  }
  .dob-fields {
    width: 100% !important;
  }
}
@media screen and (max-width: 629px) {
  .Add-child-section {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 409px) {
  .Add-child-section {
    margin-left: auto;
    margin-right: auto;
  }
}
.thankyou_emerdency {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.thankyou_emerdency .thankyou-heading {
  width: 100%;
  text-align: center;
}
.thankyou_emerdency .thankyou-heading .thankyou-head {
  font-size: 45px;
  font-weight: 600;
  color: #e9531d;
}
.thankyou_emerdency .thankyou-heading .thankyou-line {
  display: inline-block;
  width: 190px;
  height: 3px;
  background-color: #e9531d;
}
.thankyou_emerdency .inner-thanks {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .thankyou_emerdency {
    width: 100%;
  }
  .thankyou_emerdency .inner-thanks {
    font-size: 20px;
  }
  .thankyou_emerdency .thankyou-heading .thankyou-head {
    font-size: 30px;
  }
  .thankyou-line {
    background-color: #dadada !important;
    width: 80% !important;
    height: 1px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 484px) {
  .thankyou_emerdency .inner-thanks {
    font-size: 15px;
  }
  .thankyou_emerdency .thankyou-heading .thankyou-head {
    font-size: 25px;
  }
}
@media screen and (max-width: 390px) {
  .thankyou_emerdency .inner-thanks {
    font-size: 12px !important;
  }
}
.inner-thanks {
  padding: 40px;
  position: relative;
  width: 100% !important;
  margin-top: 10px !important;
}

.right-teeth {
  position: absolute;
  bottom: 0px;
  right: 12%;
}

.lift-teeth {
  position: absolute;
  top: 0px;
  left: 15%;
}

@media screen and (max-width: 1280px) {
  .right-teeth {
    right: 8%;
  }
  .lift-teeth {
    position: absolute;
    top: 0px;
    left: 10%;
  }
}
@media screen and (max-width: 1099px) {
  .right-teeth {
    right: 5%;
  }
  .lift-teeth {
    position: absolute;
    top: 0px;
    left: 7%;
  }
}
@media screen and (max-width: 593px) {
  .right-teeth {
    right: 3%;
  }
  .lift-teeth {
    position: absolute;
    top: -10px;
    left: 5%;
  }
}
@media screen and (max-width: 860px) {
  .thankyou-line {
    display: none !important;
  }
}
.views-section {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.view-heading {
  text-align: center;
  margin-bottom: 30px;
}

.view-head {
  font-size: 45px;
  color: #e9531d;
  font-weight: 600;
}

.view-head2 {
  font-size: 25px;
  color: #e9531d;
  font-weight: 600;
}

.view-line {
  display: inline-block;
  width: 270px;
  height: 3px;
  background-color: #e9531d;
  transform: translateY(-10px);
}

.inner-view {
  margin-top: 60px;
  width: 100%;
}
.inner-view .view-nav {
  width: 100%;
  border-bottom: 2px solid #dadada;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.inner-view .view-nav .appoint-box {
  flex-basis: 14%;
}
.inner-view .view-nav .appoint-box span svg {
  width: 20px;
}
.inner-view .view-nav .appoint-texts2 {
  flex-basis: 20% !important;
}
.inner-view .view-nav .appoint-texts {
  color: #676767;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}
.inner-view .view-detail-card {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.inner-view .view-detail-card .appoint-person-name {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  font-size: 23px;
  font-weight: 500;
  color: #ffffff;
  background-color: #e9531d;
  line-height: 50px;
}
.inner-view .view-detail-card .view-inner-detail-card {
  background-color: transparent;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.inner-view .view-detail-card .view-inner-detail-box {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}
.inner-view .view-detail-card .view-inner-detail-box .patient-info-box {
  flex-basis: 25%;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
}
.inner-view .view-detail-card .view-inner-detail-box .patient-info-box .pateint-info-bold {
  color: #000000;
  font-size: 15px;
  font-weight: 800;
}
.inner-view .view-detail-card .view-inner-detail-box .patient-info-box .pateint-info-light {
  color: #676767;
  font-size: 13px;
  margin-top: 4px;
}
.inner-view .view-detail-card .view-inner-detail-box .patient-info-box .patient-detail-box {
  margin-bottom: 35px;
}
.inner-view .view-inner-detail-box-mbl {
  justify-content: unset !important;
}
.inner-view .leave-review-btn {
  padding-bottom: 20px;
}
.inner-view .leave-review-btn p {
  width: 250px;
  height: 40px;
  background-color: #e9531d;
  box-shadow: 0px 0px 2px #b8b5b5;
  line-height: 40px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.inner-view {
  margin-top: 60px;
  width: 100%;
}
.inner-view .view-nav {
  width: 100%;
  border-bottom: 2px solid #dadada;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.inner-view .view-nav .appoint-box {
  flex-basis: 14%;
}
.inner-view .view-nav .appoint-box span svg {
  width: 20px;
}
.inner-view .view-nav .appoint-texts2 {
  flex-basis: 20% !important;
}
.inner-view .view-nav .appoint-texts {
  color: #676767;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
  margin-top: 5px !important;
}
.inner-view .appoints-detail-card {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #dadada;
}
.inner-view .appoints-detail-card .appoint-person-name {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  font-size: 23px;
  font-weight: 500;
  color: #ffffff;
  background-color: #e9531d;
  line-height: 50px;
}
.inner-view .appoints-detail-card .appoint-inner-detail-card {
  background-color: #fff7f4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.inner-view .appoints-detail-card .appoint-inner-detail-box-mbl {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 50px;
}
.inner-view .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
}
.inner-view .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-bold {
  color: #000000;
  font-size: 15px;
  font-weight: 800;
}
.inner-view .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-light {
  color: #676767;
  font-size: 13px;
  margin-top: 4px;
}
.inner-view .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .patient-detail-box {
  flex-basis: 45%;
  margin-bottom: 15px;
}
.inner-view .leave-review-btn {
  padding-bottom: 20px;
}
.inner-view .leave-review-btn p {
  width: 250px;
  height: 40px;
  background-color: #e9531d;
  box-shadow: 0px 0px 2px #b8b5b5;
  line-height: 40px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 1120px) {
  .appoint-box {
    flex-basis: 20% !important;
  }
  .appoint-box .appoint-texts {
    font-size: 11px !important;
  }
  .appoint-box span svg {
    width: 16px !important;
    height: 16px !important;
  }
}
.Mobile-version-view {
  display: none;
}

@media screen and (max-width: 1000px) {
  .inner-view .appoints-detail-card .appoint-inner-detail-box .patient-info-box {
    flex-basis: 30%;
  }
  .inner-view .appoints-detail-card .appoint-inner-detail-box {
    padding-left: 20px;
  }
}
@media screen and (max-width: 890px) {
  .inner-view .appoints-detail-card .appoint-inner-detail-box .patient-info-box .pateint-info-light {
    font-size: 11px;
  }
}
.add-kid {
  display: none;
}

.Mobie-version-view {
  display: none;
}

@media screen and (max-width: 794px) {
  .inner-view .view-detail-card .view-inner-detail-box .patient-info-box {
    flex-basis: 50%;
  }
  .inner-view .view-detail-card {
    width: 90% !important;
  }
  .inner-view .appoints-detail-card .appoint-person-name {
    font-size: 18px !important;
  }
  .Desktop-version-view {
    display: none !important;
  }
  .Mobie-version-view {
    display: block;
  }
  .appoint-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 15px;
  }
  .add-child {
    display: none !important;
  }
  .add-kid {
    display: block !important;
  }
  .view-head {
    font-size: 30px;
  }
  .view-line {
    width: 80%;
    background-color: #dadada;
    height: 1px;
    transform: translateX(0px);
  }
}
@media screen and (max-width: 431px) {
  .inner-view .view-detail-card .view-inner-detail-box .patient-info-box .pateint-info-light {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 629px) {
  .views-section {
    width: 100%;
  }
}
@media screen and (max-width: 525px) {
  .inner-view .appoints-detail-card {
    width: 85%;
  }
  .inner-view .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-light {
    font-size: 11px;
  }
}
@media screen and (max-width: 370px) {
  .inner-view .appoints-detail-card {
    width: 95%;
  }
  .inner-view .appoints-detail-card .appoint-inner-detail-box-mbl .patient-info-box .pateint-info-light {
    font-size: 10px;
  }
}
.login-section {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
}

.login-heading {
  text-align: center;
  margin-bottom: 30px;
}

.login-head {
  font-size: 45px;
  font-weight: 600;
}

.login-line {
  display: inline-block;
  width: 90px;
  height: 3px;
  background-color: #e9531d;
  transform: translateY(-10px);
}

.login-field-box1 {
  width: 100%;
  height: 55px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}

.login-field-box1::before {
  position: absolute;
  content: "Email Or User ID";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}

.login-field-box1 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}

.login-field-box2 {
  width: 100%;
  height: 55px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 2px rgb(185, 184, 184);
}

.login-field-box2::before {
  position: absolute;
  content: "Password";
  top: -10px;
  background-image: linear-gradient(#fffcf6, #ffffff);
  padding-left: 4px;
  padding-right: 4px;
  color: #e9531d;
  font-weight: 600;
  font-size: 13px;
}

.login-field-box2 input {
  color: #000000;
  font-weight: 700;
  width: 100%;
  background-color: transparent;
}

.login-btn-filed {
  width: 100%;
  height: 55px;
  text-align: center;
  line-height: 55px;
  background-color: #e9531d;
  color: #ffffff;
  border-radius: 7px;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 802px) {
  .login-section {
    width: 75%;
  }
}
@media screen and (max-width: 802px) and (max-width: 504px) {
  .login-section {
    width: 90%;
  }
  .login-head {
    font-size: 30px !important;
  }
  .login-line {
    width: 100%;
    background-color: #dadada !important;
    height: 1px;
  }
}
.second-revivews {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rev-card {
  width: 49%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 30px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 2px #707070;
  margin-bottom: 35px;
  position: relative;
}

.rev-card::after {
  position: absolute;
  content: "";
  height: 40px;
  width: 40px; /* Set a width to maintain the aspect ratio or use 'auto' if preferred */
  right: 10px;
  top: 10px;
  background-image: url("/assets/images/svg-icons/quotes.svg");
  background-size: contain; /* This ensures the image fits within the dimensions, maintaining its aspect ratio */
  background-repeat: no-repeat;
  background-position: center;
}

.rev-top {
  display: flex;
  align-items: center;
}

.myMarc {
  font-size: 15px;
  color: #1D1D1D;
  font-weight: 600;
}

.December {
  font-size: 13px;
  color: #676767;
}

.rev-texts {
  margin-top: 20px;
  font-size: 15px;
  color: #3E3E3E;
  font-weight: 500;
  padding-right: 30px;
}

.rev-stars {
  margin-top: 20px;
}

@media screen and (max-width: 620px) {
  .second-revivews {
    width: 90%;
  }
  .rev-texts {
    padding-right: 0px !important;
  }
  .rev-card {
    width: 100%;
  }
}
.location-landing .heading-underline {
  margin: 0 0 2rem;
}
.location-landing .heading-underline::after {
  left: 0;
  width: 150px;
  bottom: -10px;
}
.location-landing h3 {
  color: #e9531d;
  margin-bottom: 0.8rem;
}
.location-landing p {
  margin-bottom: 1.2rem;
}
.location-landing img {
  margin: 0 auto;
  max-width: 75%;
  height: auto;
}
.location-landing ul li {
  display: flex;
  align-items: center;
}
.location-landing ul li::before {
  color: #e8541c;
  content: "✓";
  display: table-cell;
  font-size: 30px;
  margin-right: 10px;
  width: 30px;
  height: 40px;
}

.alt-list {
  display: flex;
  align-items: flex-start !important;
}
.alt-list::before {
  color: #e8541c;
  content: "✓";
  display: table-cell;
  font-size: 30px;
  margin-right: 10px;
  width: 30px;
  height: 40px;
}