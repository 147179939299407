.Add-child-section {

    .child-heading-t {
      font-size: 25px;
      font-weight: 700;
      color: #e9531d;
    }
  
    .child-detail-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .child-fields {



      width: 100%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields::before {
      position: absolute;
      content: "First Name";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields2 {
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields2::before {
      position: absolute;
      content: "Last Name";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }


    .child-fields-title{

      &::after{
        left: 21px;
        top: -10px;
        position: absolute;
        content: "Title";
        background-image: linear-gradient(#fffcf6, #ffffff);
        padding-left: 4px;
        padding-right: 4px;
        color: #e9531d;
        font-weight: 600;
        font-size: 13px;
        z-index: 2;
      }

      select{
        width: 100px;      
      }
    }




    .child-fields2 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-dob {
      p {
        font-size: 25px;
        font-weight: 700;
        color: #e9531d;
      }
    }
    .dob-fields {
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      padding-right: 30px;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
      display: flex;
      justify-content: space-between;
      span {
        svg {
          width: 25px;
          height: 25px;
          margin-left: auto;
        }
      }
    }
    .dob-fields input {
      color: #000000;
      font-weight: 700;
      width: 80%;
      background-color: transparent;
    }
  }
  .child-register-btn {
  
    p {
      width: 550px;
      height: 60px;
      background-color: #e9531d;
      box-shadow: 0px 0px 4px #615f5f;
      line-height: 60px;
      color: #ffffff;
      margin-left: auto;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      font-size: 19px;
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 794px) {
    .child-register-btn {
      p {
        width: 100%;
      }
    }
  
    .child-detail-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: unset;
    }
    .child-fields {
      width: 100% !important;
    }
    .dob-fields {
      width: 100% !important;
    }
  }
  
  @media screen and (max-width: 629px) {
    .Add-child-section {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 409px) {
    .Add-child-section {
      margin-left: auto;
      margin-right: auto;
    }
  }
  