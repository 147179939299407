
  .blog-card-section {
    margin-top: 30px;
    max-width: 90%;
    // border: 1px solid black;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .blog-card {
    border-radius: 5px;
    box-shadow: 4px 4px 3px #eee;
    border: 1px solid #eee;
    margin: 10px;
    margin-bottom: 20px;
  }
  .blog-img {
    width: 100%;
    height: 210px;
  }
  .blog-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .blog-heading {
    color: #111827;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    a{
      color: $default-text-color;
    }
  }
  .inner-blog-card {
    padding: 25px;
  }
  .blog-text {
    color: #6b7280;
    font-size: 13px;
    a{
      color: $default-text-color;
    }
  }
  .view-more-blog {
    color: #e9531d;
    font-size: 13px;
  }
  .blog-person {
    display: flex;
    align-items: center;
  }
  .blog-men-info {
    display: flex;
    flex-direction: column;
  }
  .blog-person-name {
    color: #111827;
    font-size: 14px;
  }
  .bloag-date {
    color: #6b7280;
    font-size: 12px;
  }
  @media screen and (max-width: 973px) {
    .blog-card-section {
      width: 90%;
      justify-content: space-between;
    }
    .blog-card {
      width: 47%;
      border-radius: 5px;
      box-shadow: 4px 4px 3px #eee;
      margin: 10px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 755px) {
    .blog-card-section {
      width: 90%;
      justify-content: center;
    }
    .blog-card {
      width: 70%;
      border-radius: 5px;
      box-shadow: 4px 4px 3px #eee;
      margin: 10px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 519px) {
    .blog-card {
      width: 100%;
      border-radius: 5px;
      box-shadow: 4px 4px 3px #eee;
      margin: 10px;
      margin-bottom: 20px;
    }
    .inner-blog-card {
      padding: 10px;
    }
  }
  

  

.view-more-blog{
  a{
    color:$primary-color;
  }
}
