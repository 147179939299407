.reviewe {
  margin-bottom: 25px;
  margin-top: 50px;
}
.reviewBox {
  width: 90%;
  height: 100vh;
  padding: 30px 0 10px 50px;

  overflow: hidden;
}
.headerss {
  display: flex;
  align-items: center;
  gap: 30%;
}
#rev {
  color: orange;
  margin-bottom: 15px;
}
#heading {
  font-weight: 600;
  font-size: 35px;
}
.arrows {
  display: flex;
  gap: 10px;
}
.prevArrow {
  background: orange;
}
.nextArrow {
  background: black;
}
.prevArrow,
.nextArrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prevArrow img,
.nextArrow img {
  width: 40%;
}

/*start of main slider*/
.slider {
  position: relative;
  width: 2000px;
  height: 260px;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  gap: 10px;
  transform: translateX(0px);
  transition: all 0.5s ease-in-out;
}
.card {
  width: 400px;
  height: 100%;
  padding: 40px 40px 0px 40px;
  box-shadow: 5px 5px 10px -6px rgba(0, 0, 0, 0.1), -5px 5px 10px -6px rgba(0, 0, 0, 0.1), 0px -1px 8px -4px rgba(0, 0, 0, 0.1);

  border-radius: 10px;
  background: #ffff;
}
#reviews .card {
  pointer-events: none;
}
.cardText {
  font-size: 15px;
  color: #3e3e3e;
  line-height: 35px !important;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Lato";
  font-style: normal;
}
.cardTop {
  display: flex;
  justify-content: space-between;
}
.leftS {
  display: flex;
  align-items: center;
}
.profile {
  width: 50px;
  margin-right: 10px;
}
#userName {
  font-size: 15px;
  font-weight: 600;
  margin-top: -8px;
  margin-bottom: 8px;
}
#datee {
  font-size: 10px;
  color: grey;
}
.profile img {
  width: 100%;
  object-fit: cover;
}

.glide__slide {
  height: 400px;
}
.card {
  width: 100%;
}
.glide__track {
  // margin-left: 100px;
}
.glide__arrow {
  right: 10%;
  top: -20%;
  background-color: black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.glide__arrow--prev {
  transform: translate(-110%, -120%);
}
.glide__arrow--next {
  transform: translate(0, -120%);
}

.reviewBox {
  width: 90%;
  height: 100vh;
  padding: 30px 0 10px 50px;

  overflow: hidden;
}
.headerss {
  display: flex;
  align-items: center;
  gap: 30%;
}
#rev {
  color: orange;
  margin-bottom: 15px;
}
#heading {
  font-weight: 600;
  font-size: 35px;
}
.arrows {
  display: flex;
  gap: 10px;
}

.nextArrow {
  background: black;
}
.prevArrow,
.nextArrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prevArrow img,
.nextArrow img {
  width: 40%;
}

/*start of main slider*/
.slider {
  position: relative;
  width: 2000px;
  height: 260px;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  gap: 10px;
  transform: translateX(0px);
  transition: all 0.5s ease-in-out;
}

.cardText {
  margin-top: 20px;
}
.cardText p {
  font-size: 14px;
  line-height: 20px;
}
.stars {
  margin-top: 25px;
}

.glide__arrow--prev {
  background-color: #e9531d !important;
}
.rewi-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4% !important;
}
.review-head {
  display: inline-block;
  color: #e9531d;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 10px;
}
.rewi-heading .line-reviwe {
  display: inline-block;
  width: 160px;
  height: 5px;
  background-color: #e9531d;
  border-radius: 5px;
}
.what-user-saw {
  padding-left: 100px;
}
.rev-start {
  color: #e9531d;
  font-size: 17px;
  font-weight: 600;
}
.revi-start-heahing {
  color: black;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 10px;
}

.reviewBox {
  width: 90%;
  height: 100vh;
  padding: 30px 0 10px 50px;
  overflow: hidden;
}
.headerss {
  display: flex;
  align-items: center;
  gap: 30%;
}
#rev {
  color: orange;
  margin-bottom: 15px;
}
#heading {
  font-weight: 600;
  font-size: 35px;
}
.arrows {
  display: flex;
  gap: 10px;
}
.prevArrow {
  background: orange;
}
.nextArrow {
  background: black;
}
.prevArrow,
.nextArrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prevArrow img,
.nextArrow img {
  width: 40%;
}

/*start of main slider*/
.slider {
  position: relative;
  width: 2000px;
  height: 260px;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  gap: 10px;
  transform: translateX(0px);
  transition: all 0.5s ease-in-out;
}

.rightComma img {
  width: 100%;
  object-fit: cover;
}
#mobile-review {
  display: none;
}
.mbl-revi-line {
  display: none;
  width: 80%;
  height: 1px;
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1193px) {
  .dental-problem {
    margin-top: 200px;
  }

  #desktop-rewiew {
    display: none;
  }
  #mobile-review {
    display: block;
  }

  .reviewBox {
    border: none;
    width: 100%;
    overflow: visible;
  }
  .slider {
    width: 80%;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
  .what-user-saw {
    display: none;
  }
  .line-reviwe {
    display: none !important;
  }
  .mbl-revi-line {
    display: block;
    margin-bottom: -25px;
  }
  .arrows {
    display: none;
  }
  .card {
    position: relative;
    min-height: 200px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 976px) {
  .cardText p {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .reviewBox {
    padding-right: 40px;
  }
  .dental-problem {
    margin-top: 80%;
  }

  #rev {
    font-weight: 600;
    text-align: center;
    font-size: 50px;
  }
  #heading {
    display: none;
  }
  .card {
    padding-right: 0px;
    width: 100%;
  }
  .rightComma {
    display: none;
  }
}
@media screen and (max-width: 792px) {
  .dental-problem {
    margin-top: 120%;
  }
}

@media screen and (max-width: 525px) {
  .cardText p {
    font-size: 13px;
  }
}
@media screen and (max-width: 465px) {
  .cardText p {
    font-size: 11px;
  }
}
@media screen and (max-width: 550px) {
  .reviewBox {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .dental-problem {
    margin-top: 170%;
  }
  .slider {
    width: 100%;
    min-height: 44%;
  }

  .cardText p {
    font-size: 11px;
  }
}
@media screen and (max-width: 430px) {
  .dental-problem {
    margin-top: 190%;
  }
}
@media screen and (max-width: 382px) {
  .dental-problem {
    margin-top: 210%;
  }
}
@media screen and (max-width: 344px) {
  .dental-problem {
    margin-top: 240%;
  }
}
