.Add-new-section {
    .new-heading-t {
      font-size: 25px;
      font-weight: 700;
      color: #e9531d;
    }
  
    .new-detail-inner {
      width: 100%;
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
    }
    .new-fields {
      width: 100%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
      display: flex;
      justify-content: space-between;
      span {
        cursor: pointer;
        svg {
          width: 30px;
          height: 30px;
          margin-left: auto;
        }
      }
    }

    .new-fields::before {
      position: absolute;
      content: "Enter New Password";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }

    .new-fields-existing::before {
      content: "Current Password";
    }

    .new-fields input {
      color: #000000;
      font-weight: 700;
      width: 80%;
      background-color: transparent;
    }
    .new-fields2 {
      width: 100%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
      display: flex;
      justify-content: space-between;
      span {
        cursor: pointer;
  
        svg {
          width: 30px;
          height: 30px;
          margin-left: auto;
        }
      }
    }
    .new-fields2::before {
      position: absolute;
      content: "Re-Enter New Password";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .new-fields2 input {
      color: #000000;
      font-weight: 700;
      width: 80%;
      background-color: transparent;
    }
  }
  .old-pass {
    p {
      font-size: 25px;
      font-weight: 700;
      color: #e9531d;
    }
  }
  .old-fields {
    margin-top: 20px;
    width: 48%;
    height: 55px;
    border: 1px solid #e9531d;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 15px;
    background-color: #ffffff;
    padding-right: 30px;
    position: relative;
    box-shadow: 2px 2px 2px rgb(185, 184, 184);
    display: flex;
    justify-content: space-between;
    span {
      svg {
        width: 25px;
        height: 25px;
        margin-left: auto;
      }
    }
  }
  .old-fields::before {
    position: absolute;
    content: "Enter Old Password";
    top: -10px;
    background-image: linear-gradient(#fffcf6, #ffffff);
    padding-left: 4px;
    padding-right: 4px;
    color: #e9531d;
    font-weight: 600;
    font-size: 13px;
  }
  .old-fields input {
    color: #000000;
    font-weight: 700;
    width: 80%;
    background-color: transparent;
  }
  .new-register-btn {
    p {
      margin:0 auto;
      width: 550px;
      height: 60px;
      background-color: #e9531d;
      box-shadow: 0px 0px 4px #615f5f;
      line-height: 60px;
      color: #ffffff;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      font-size: 19px;
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 794px) {
    .new-fields2 {
      span {
        display: none !important;
      }
    }
    .new-fields {
      span {
        display: none !important;
      }
    }
    .new-register-btn {
      p {
        width: 100%;
      }
    }
  
    .new-detail-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      justify-content: unset;
    }
    .new-fields {
      width: 100% !important;
    }
    .old-fields {
      width: 100% !important;
    }
  }
  
  