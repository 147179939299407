.rating-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    .rateing-heading {
      width: 100%;
      text-align: center;
  
      .rating-head {
        font-size: 45px;
        font-weight: 600;
        color: #e9531d;
      }
      .rating-line {
        display: inline-block;
        width: 120px;
        height: 3px;
        background-color: #e9531d;
      }
    }
    .appreciation {
      margin-top: 30px;
      color: #000000;
      font-size: 32px;
      font-weight: 600;
    }
    .about-dentist {
      margin-top: 25px;
      font-size: 25px;
      font-weight: 600;
      color: #e9531d;
    }
    .Rate-dentist1 {
      color: #000000;
      font-size: 17px;
      font-weight: 700;
      margin-top: 20px;
    }
    .share-expo {
      color: #676767;
      font-size: 15px;
      margin-top: 7px;
    }
    .rate-me {
      margin-top: 25px;
      width: 100%;
      display: flex;
    }
    .ratin-box {
      width: 60px;
      height: 35px;
      text-align: center;
      line-height: 32px;
      margin-right: 20px;
      border-radius: 3px;
      font-weight: 600;
      border: 1px solid #e9531d;
      color: #e9531d;
      cursor: pointer;
    }
    .ratin-box2 {
      width: 60px;
      height: 35px;
      text-align: center;
      line-height: 32px;
      margin-right: 20px;
      border-radius: 3px;
      font-weight: 600;
      border: 1px solid #e9531d;
      color: #e9531d;
      cursor: pointer;
    }
    .orange-color {
      background-color: #e9531d;
      color: #ffffff;
    }
    .Comments {
      margin-top: 25px;
      color: #000000;
      font-size: 17px;
      font-weight: 700;
    }
    .comment-area {
      width: 100%;
      border: 1px solid #e9531d;
      height: 250px;
      border-radius: 7px;
      margin-top: 5px;
      padding: 20px;
  
      textarea {
        outline: none;
        border: none;
        color: #676767;
        width: 100%;
        height: 100%;
        background: transparent;
        resize: none;
      }
    }
  }
  .rating-btn p {
    cursor: pointer;
    margin-top: 30px;
    width: 350px;
    height: 50px;
    line-height: 50px;
    background-color: #e9531d;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    margin-left: auto;
    box-shadow: 3px 3px 3px #acabab;
  }
  
  @media screen and (max-width: 568px) {
    .appreciation {
      font-size: 25px !important;
    }
    .rating-section .about-dentist {
      font-size: 20px !important;
    }
    .rating-btn p {
      width: 100% !important;
    }
    .rating-section .rateing-heading .rating-head {
      font-size: 30px !important;
    }
    .rating-section .rateing-heading {
      .rating-line {
        width: 100% !important;
        background-color: #dadada !important;
        height: 1px !important;
      }
    }
  }
  @media screen and (max-width: 355px) {
    .appreciation {
      font-size: 21px !important;
    }
    .rating-section .about-dentist {
      font-size: 17px !important;
    }
    .rating-section .ratin-box {
      width: 50px;
      height: 30px;
      line-height: 30px;
      font-weight: 500;
      font-size: 12px;
    }
  }
  