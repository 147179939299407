
#appointment-form{
  padding: 0;
}

.Add-child-section .dob-fields{
  width:200px !important;
  input{
    color: grey !important;
  }
}

.disabled-custom{

  cursor: not-allowed !important;

  &.child-fields1,
  &.child-fields3,
  .dob-fields{
    border:1px solid grey !important;
    color: grey !important;
    opacity: 0.5;
  }

  input{
    cursor: not-allowed !important;
  }

}


.edit-detail-field {
    .child-detail-inner {
      width: 100%;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
    }
  }
  
  .Add-child-section {
    .child-heading-t {
      font-size: 25px;
      font-weight: 700;
      color: #e9531d;
    }
  
    .child-fields1 {
      width: 100%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
  
    .child-fields1 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields3 {
      width: 100%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
  
    .child-fields3 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields4 {
      width: 49%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields4::before {
      position: absolute;
      content: "House Number or Name";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields4 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields5 {
      width: 49%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields5::before {
      position: absolute;
      content: "Street or Road";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields5 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields6 {
      width: 49%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields6::before {
      position: absolute;
      content: "Town or City";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields6 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields7 {
      width: 49%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields7::before {
      position: absolute;
      content: "Post Code";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields-line2::before {
      position: absolute;
      content: "Address Line 2";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields7 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields8 {
      width: 49%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields8::before {
      position: absolute;
      content: "Contact Number";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields8 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .child-fields9 {
      width: 49%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
    }
    .child-fields9::before {
      position: absolute;
      content: "Email Address";
      top: -10px;
      background-image: linear-gradient(#fffcf6, #ffffff);
      padding-left: 4px;
      padding-right: 4px;
      color: #e9531d;
      font-weight: 600;
      font-size: 13px;
    }
    .child-fields9 input {
      color: #000000;
      font-weight: 700;
      width: 100%;
      background-color: transparent;
    }
    .Address-field {
      width: 100%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
  
      input {
        color: #000000;
        font-weight: 700;
        width: 100%;
        height: 100%;
      }
    }
    .child-dob {
      p {
        font-size: 25px;
        font-weight: 700;
        color: #e9531d;
      }
    }
    .dob-fields {

      width: 60%;
      height: 55px;
      border: 1px solid #e9531d;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #ffffff;
      padding-right: 30px;
      position: relative;
      box-shadow: 2px 2px 2px rgb(185, 184, 184);
      display: flex;
      justify-content: space-between;
      span {
        svg {
          width: 25px;
          height: 25px;
          margin-left: auto;
        }
      }
    }
    .dob-fields input {
      color: #000000;
      font-weight: 700;
      width: 80%;
      background-color: transparent;
    }
  }
  .child-register-btn {
      display: block;
      width: 100%;
      margin:0 auto;
      max-width: 300px;
      height: 60px;
      background-color: #e9531d;
      box-shadow: 0px 0px 4px #615f5f;
      line-height: 60px;
      color: #ffffff;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      font-size: 19px;
      font-weight: 600;
      color: white !important;
  }
  .Add-child-section .Address-field {
    textarea {
      display: none;
    }
  }
  
  @media screen and (max-width: 794px) {
    .Add-child-section .Address-field {

      input {
        display: none;
      }
  
      textarea {
        display: block;
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
      }
    }
    .child-register-btn {
      p {
        width: 100%;
      }
    }
    .edit-detail-field {
      .child-heading-t {
        margin-bottom: 25px;
      }
      .child-detail-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        justify-content: unset;
      }
    }
  
    .child-fields,
    .child-fields1,
    .child-fields3 {
      width: 100% !important;
    }
    .dob-fields {
      width: 100% !important;
    }
  }
  
  @media screen and (max-width: 629px) {
    .Add-child-section {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 409px) {
    .Add-child-section {
      margin-left: auto;
      margin-right: auto;
    }
  }
  