#loader-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional background overlay */
  z-index: 999999;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* Rectangle styling */
  .loader-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 550px;
    width: 800px;
    max-width: 90%;
    max-height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  svg {
    width: 40%;
    height: 40%;
    margin-bottom: 1rem;
  }

  .loader-text {
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .loader {
    transform-origin: center;
    margin: 0 auto 2rem;
    border: 16px solid #f3f3f3;
    border-top-width: 16px;
    border-top-style: solid;
    border-top-color: rgb(243, 243, 243);
    border-top: 16px solid #e8541c;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    background-color: white;
  }

  .loader-confirm {
    margin-top: auto;
    position: absolute;
    bottom: 0px;
    right: 30px;
    border: 8px solid #f3f3f3 !important;
    border-top: 8px solid #e8541c !important;
    width: 40px !important;
    height: 40px !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

#card-element {
  padding: 10px 5px;
  border: 1px solid #d3d3d3;
}

.navigate-back-container {
  color: #d3d3d3;
  a {
    color: red;
    display: inline;
    font-size: 0.9rem;
    opacity: 0.5;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}

#stripePay:disabled {
  opacity: 0.3;
}

.update-color-orange {
  color: #e9531d !important;
}

.Payment-top {
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;

  span {
    color: #5c5b60;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
  }
  .logo-mid-pic {
    width: 120px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.table-of-pay {
  a {
    display: block;
    width: 100%;
    text-align: center;
    color: $lighter-text-color;
    &:hover {
      color: $primary-color;
    }
  }

  border-top: 1px solid #40404833;
  border-bottom: 1px solid #40404833;
  height: 43px;
  line-height: 39px;
  background-color: #ffffff;
  .payment {
    border-right: 1px solid #40404833;
    border-left: 1px solid #40404833;
  }
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
  }
}

.justfortag,
.justfortag a {
  color: $primary-color;
}

.main-container {
  width: 100%;
  height: 100vh;
  background-image: url("/assets/images/wave.webp");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.pay-form {
  margin-right: auto;
  margin-left: auto;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;

  .pay-top {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .top-left {
      flex-basis: 50%;

      .pay-text {
        p {
          color: #25242b;
          font-style: normal;
          font-weight: 900;
          font-size: 21px;
          margin-bottom: 4px;
        }
      }
    }
    .top-right {
      .pay-price {
        p {
          color: #e9531d;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;

          @media screen and (max-width: 400px) {
            font-size: 28px;
          }
        }
      }
      .pay-total {
        p {
          color: #7b7b7b;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          text-align: center;
        }
      }
    }
  }
  .debitform {
    label {
      color: #7b7b7b;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
    }
    .debit-input {
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      height: 40px;
      border-radius: 6px;
      border: 1px solid #dcdcdc;
      padding-left: 10px;

      input {
        width: 90%;
        height: 100%;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .pay-btn {
    width: 100%;
    background-color: #e9531d;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    height: 40px;
    border-radius: 6px;
  }
  .or-box {
    position: relative;
    margin-top: 10px;

    .line-box::before {
      content: "";
      height: 1px;
      width: 100%;
      background-color: #dcdcdc;
      position: absolute;
      top: 50%;
      left: 0;
    }
    span {
      position: relative;
      background-color: #ffffff;
      width: 50px;
      display: inline-block;
      color: #b3b3b3;
    }
  }

  .payment-confirmation-icon {
    margin-right: 3px;
  }
  .paypal-btn {
    width: 100%;
    background-color: #f0f3f8;
    height: 40px;
    border-radius: 6px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancelt-btn {
    margin-top: 25px;
    width: 100%;
    color: #f30000;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 360px) {
    .pay-logo-mobile {
      flex-wrap: wrap;
    }
    .payment-confirmation-icon {
      margin-top: 3px;
      margin-right: 0px;
    }
  }
}
@media screen and (max-width: 525px) {
  .main-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .table-of-pay {
    img {
      width: 11px;
    }
  }
}
@media screen and (max-width: 354px) {
  .debit-input {
    input {
      width: 100px;
      height: 100%;
    }
    img {
      width: 7%;
      cursor: pointer;
    }
    .Payment-top-mbl {
      span {
        font-size: 9px !important;
      }
    }
  }
  .table-of-pay {
    span {
      font-size: 7px;
    }
    img {
      width: 8px;
    }
    #confirm-text {
      display: none;
    }
  }
}
@media screen and (min-width: 2000px) {
  .pay-form {
    top: 20%;
  }
}
@media screen and (min-width: 1500px) {
  .pay-form {
    top: 10%;
  }
}

@media screen and (max-width: 416px) {
  .table-of-pay {
    span {
      font-size: 11px;
    }
    img {
      width: 9px;
    }
  }
}
